import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import queryString from "query-string";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-cartoonizer.service";
import { resizeImage, getSpecialMessageHTML } from "../utils";
import { Context } from "../Context";
import { APP_NAME, CARTOON_STYLES, TOTAL_FILE_SIZE } from "../constants";
import { PopupShare } from "./Share/share.component";

export default class UploadImages4Cartoonizer extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 

  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      selectedFiles: undefined,
      previewImages: [],
      progress: 0,
      message: "",

      cartoonStyle: "whitebox",
      resultSizeType: queryParams.resultSizeType,

      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  onChangeOfStyle = event => {
    this.setState({
      cartoonStyle: event.target.value,
      progress: 0,
      message: ""
    });
  }

  selectFiles = async event => {
    const acceptedFiles = [];
    const images = [];
    let totalSize = 0;
    for (let i = 0; i < event.target.files.length; i++) {
      let currentFile = event.target.files[i];
      const size = currentFile.size;
      if (size < 100)  //probably an invalid image file, skip it
        continue;
      totalSize += size;
      if (totalSize > TOTAL_FILE_SIZE) 
        break;
      if (size > 2097152) {  //no need to resize when size < 2M
        try {
          currentFile = await resizeImage(currentFile);
        } catch(err) {
          this.setState({
            previewImages: undefined,
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
          });
          return;
        }
      }
      acceptedFiles.push(currentFile);
      images.push(URL.createObjectURL(currentFile));
    }

    if (acceptedFiles.length === 0) {
      this.setState({
        selectedFiles: undefined,
        previewImages: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_VALID_FILE_FOUND'),
      });
      return;
    }

    this.setState({
      selectedFiles: acceptedFiles,
      previewImages: images,
      progress: 0,
      message: ""
    });
  }

  uploadAll(files, sid, locale) {
    this.timer = null;
    this.duration = 5 * 1000 * files.length;
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.upload(files, this.state.cartoonStyle, this.state.resultSizeType, sid, locale, (event) => {
      //this.setState({
      //  progress: Math.round(((40 / Math.ceil(files.length / 5)) * event.loaded) / event.total),
      //});
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 200)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4Cartoonizer,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
            resultsFolder: reason.response.data.userFolder4Cartoonizer,
          });
        } else {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
          });
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
  }

  uploadImages(sid, locale) {
    const selectedFiles = this.state.selectedFiles;
    if (!selectedFiles) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        this.uploadAll(selectedFiles, sid, locale);
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { selectedFiles, previewImages, progress, message, cartoonStyle, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Cartoonizer') }</title>
          <meta name="description" content={ intl.get('Cartoonizer_info') } />
        </Helmet>
        <div className="title">{ intl.get('Cartoonizer') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}
        
        {(!authenticated || user.displayMode === '1') && (
          <div className="row m-2">
            <div className="col-12 text-center">
              <img src="/images/cartoonizer2.jpg" alt="Cartoonizer example 2" className="img-fluid" />
            </div>
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && this.props.isLargeScreen && (
          <div className="row mx-1 my-4">
            <div className="col-4 align-self-center introduction">
               { intl.getHTML('Cartoonizer_description') }
            </div>
            <div className="col-8 text-center embed-responsive embed-responsive-16by9"> 
              <iframe width="560" height="315" src={dynamicText.cartoonizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
            </div>
          </div>
        )}
        {(!authenticated || user.displayMode === '1') && !this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('Cartoonizer_description') }
              </div>
              <div className="embed-responsive embed-responsive-16by9"> 
                <iframe width="560" height="315" src={dynamicText.cartoonizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
              </div>
            </div>
          </div>
        )}

        {!authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              { intl.getHTML('MESSAGE_LOGIN_BENIFIT') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('Cartoonizer_instruction', { size_in_mb: TOTAL_FILE_SIZE / 1048576 }) } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" multiple accept="image/*" onChange={this.selectFiles} />
            </label>
          </div>
        </div>

        {previewImages && (
          <div className="row m-2">
            <div className="col-12">
              {previewImages.map((img, i) => {
                return <img className="preview" src={img} alt={"image-" + i}  key={i}/>;
              })}
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Cartoon_Style') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfStyle}>
              {CARTOON_STYLES.map(item => {
                return (<option key={item.value} value={item.value} defaultValue={cartoonStyle}>{item.text}</option>);
              })}
            </select>
          </div>
        </div>

        {cartoonStyle && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Cartoonizer_Sample_Effect') }
            </div>
            <div className="col-md-9">
              <img className="preview" src="/images/audrey2.jpg" alt="" />&nbsp;
              <img className="preview" src={"/images/cartoonized_audrey2_" + cartoonStyle + ".jpg"} alt="" />
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!selectedFiles || progress}
              onClick={() => this.uploadImages(sid, locale)}
              >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
        </div>

        {selectedFiles && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan1') }
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.getHTML('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
