//import intl from 'react-intl-universal';

export const LOCAL_SERVER_BASE_URL = "http://localhost:9216";    //local server
export const DEV_SERVER_BASE_URL = "http://192.168.2.88/api";  //development
export const SERVER_API_BASE_URL3 = "https://aitransformer.net/api";  //server1
export const SERVER_API_BASE_URL4 = "https://aitransformer.net/remoteapi";  //server2
export const SERVER_API_BASE_URL5 = "https://aitransformer.videoplus.studio/api";  //server3
export const SERVER_API_BASE_URL6 = "https://aitransformer.net/remoteapi1";  //server3
export const SERVER_API_BASE_URL = SERVER_API_BASE_URL5;    //main server for cpu functions, dynamic contents and sharing links
export const SERVER_API_BASE_URL2 = SERVER_API_BASE_URL5;    //server for gpu functions
//export const SERVER_API_BASE_URL2 = "https://aifinancials.net/transformerapi";  //gpu server
export const GA_MEASUREMENT_ID = "G-YRP1CFM3ND";    //for aitransformer.net
//export const GA_MEASUREMENT_ID = "G-XZWLYC1YQQ";    //for aiblockchain.art
export const useNewServer4SpeechSynthesizer = false;   //set to false for local and development server

export const CARTOON_STYLES = [
  { value:"whitebox", text:"Whitebox" },
  { value:"hayao", text:"Hayao" },
  { value:"hosoda", text:"Hosoda" },
  { value:"shinkai", text:"Shinkai" },
  { value:"paprika", text:"Paprika" },
  { value:"celeba_distill", text:"Celeba Distill" },
  { value:"face_paint1", text:"Face Paint 1" },
  { value:"face_paint2", text:"Face Paint 2" },
];

export const REGION_TO_APPLY_OPTIONS = [
  { value:"all", text:"All" },
  { value:"foreground", text:"Foreground" },
  { value:"background", text:"Background" },
];

export const STYLE_OPTIONS = [
  { value:"unselected", text:"Select style..." },
  { value:"custom", text:"*** Custom Style ***" },
  { value:"Picasso1", text:"Les Demoiselles d’Avignon, Pablo Picasso" },
  { value:"Picasso2", text:"Portrait of Daniel-Henry Kahnweiler, Pablo Picasso" },
  { value:"Picasso3", text:"Guernica, Pablo Picasso" },
  { value:"VanGogh1", text:"The Starry Night, Vincent van Gogh" },
  { value:"VanGogh2", text:"Vase with 12 Sunflowers, Vincent van Gogh" },
  { value:"VanGogh3", text:"Boats at Saintes Maries, Vincent Van Gogh" },
  { value:"VanGogh4", text:"Long Grass with Butterflies, Vincent Van Gogh" },
  { value:"VanGogh5", text:"Self Portrait with Felt Hat, Vincent Van Gogh" },
  { value:"Monet1", text:"Impression, Sunrise, Claude Monet" },
  { value:"Monet2", text:"The Seine at Bougival in the Evening, Claude Monet" },
  { value:"Monet3", text:"Water Lilie, Claude Monet" },
  { value:"Renoir1", text:"View of Venice Fog, Pierre-Auguste Renoir" },
  { value:"Renoir2", text:"Child with Punch Doll, Pierre-Auguste Renoir" },
  { value:"Renoir3", text:"A Walk in the Woods, Pierre-Auguste Renoir" },
  { value:"Matisse1", text:"The Joy of Life, Henri Matisse" },
  { value:"Matisse2", text:"Woman with a Hat, Henri Matisse" },
  { value:"Aivazovsky1", text:"Fleeing from The Storm, Ivan Aivazovsky" },
  { value:"Aivazovsky2", text:"The Bay of Naples at Night, Ivan Aivazovsky" },
  { value:"Aivazovsky3", text:"The Ninth Wave, Ivan Aivazovsky" },
  { value:"Cezanne1", text:"Path at The Entrance to The Forest, Paul Cezanne" },
  { value:"Cezanne2", text:"Self Portrait, Paul Cezanne" },
  { value:"DaVinci1", text:"Portrait of Mona Lisa del Giocondo, Leonardo da Vinci" },
  { value:"Heade1", text:"Cattleya Orchid and Three Hummingbirds, Martin Heade" },
  { value:"Pissarro1", text:"Apple Picking at Eragny-sur-Epte, Camille Pissarro" },
  { value:"Seurat1", text:"A Sunday Afternoon on the Island of La Grande Jatte, Georges Seurat" },
  { value:"Botticelli1", text:"The Birth of Venus, Sandro Botticelli" },
  { value:"Rousseau1", text:"Exotic Landscape, Henri Rousseau" },
  { value:"Keeffe1", text:"Red Canna, Georgia O'Keeffe" },
  { value:"Munch1", text:"The Scream, Edvard Munch" },
  { value:"Martin1", text:"Fountain in Marquayrol, Henri Martin" },
  { value:"Vallotton1", text:"Landscape of Ruins And Fires, Felix Vallotton" },
  { value:"Hopper1", text:"Nighthawks, Edward Hopper" },
  { value:"Bierstadt1", text:"Bavarian Landscape, Albert Bierstadt" },
  { value:"Homer1", text:"The Blue Boat, Winslow Homer" },
  { value:"Picabia1", text:"Udnie, Francis Picabia" },
  { value:"Haeckel1", text:"Orchidaceae, Ernst Haeckel" },
  { value:"Malevich1", text:"Woodcutter, Kazimir Malevich" },
  { value:"Kandinsky1", text:"Composition VII, Wassily Kandinsky" },
  { value:"Afremov1", text:"Misty Mood, Leonid Afremov" },
  { value:"Haring1", text:"Flowers IV, Keith Haring" },
  { value:"Black1", text:"Music, Dorrit Black" },
  { value:"Bortnyik1", text:"Blue-Red Composition, Sandor Bortnyik" },
  { value:"Wescoat1", text:"June Tree, Natasha Wescoat" },
  { value:"Chirico1", text:"Metaphysical Interior with Biscuits, Giorgio de Chirico" },
  { value:"Blake1", text:"The Great Red Dragon And The Woman Clothed with The Sun, William Blake" },
  { value:"Ray1", text:"Jazz, Man Ray" },
  { value:"Klee1", text:"The Lamb, Paul Klee" },
  { value:"Marc1", text:"The Fate of The Animals, Franz Marc" },
  { value:"Molk1", text:"The Spring Hall of Golestan Palace, Kamal-ol-Molk" },
  { value:"Efendi1", text:"Ebru, Hatip Mehmed Efendi" },
  { value:"Gaulli1", text:"The Apotheosis of St. Ignatius, Giovanni Battista Gaulli" },
  { value:"Shi1", text:"Bamboo, Shi Tao" },
  { value:"Qian1", text:"Wang Xizhi, Qian Xuan" },
  { value:"Chen1", text:"Magnolia and Erect Rock, Chen Hongshou" },
  { value:"Xu1", text:"Galloping Horse, Xu Beihong" },
  { value:"Qi1", text:"Birds of Paradise, Qi Baishi" },
  { value:"Hakuin1", text:"Daruma in Red, Hakuin Ekaku" },
  { value:"Hokusai1", text:"The Great Wave off Kanagawa, Hokusai" },
  { value:"DecorativePattern1", text:"Decorative Pattern 1" },
  { value:"DecorativePattern2", text:"Decorative Pattern 2" },
  { value:"DecorativePattern3", text:"Decorative Pattern 3" },
  { value:"PinwheelGalaxy", text:"The Pinwheel Galaxy" },
  { value:"MosaicLady", text:"Stained Glass Mosaic of A Lady Holding A Flower" },
];

export const STYLE_MODE_OPTIONS = [
  { value:"0", text:"Single - generate six results of various intensity for the selected style" },
//  { value:"1", text:"Batch 1 - generate one result of full intensity per every predefined style (6 minutes)" },
  { value:"2", text:"Batch 1 - generate one result of full intensity per each of the first 10 predefined styles" },
  { value:"3", text:"Batch 2 - generate one result of full intensity per each of the second 10 predefined styles" },
  { value:"4", text:"Batch 3 - generate one result of full intensity per each of the third 10 predefined styles" },
  { value:"5", text:"Batch 4 - generate one result of full intensity per each of the fourth 10 predefined styles" },
  { value:"6", text:"Batch 5 - generate one result of full intensity per each of the fifth 10 predefined styles" },
  { value:"7", text:"Batch 6 - generate one result of full intensity per each of the sixth 10 predefined styles" },
  { value:"8", text:"Batch 7 - generate one result of full intensity per each of the remaining predefined styles" },
];

export const MULTISTYLE_MODE_OPTIONS = [
  { value:"0", text:"Single - generate one result for the selected styles" },
  { value:"1", text:"Batch 1 - generate one result for each combination of the selected styles" },
//  { value:"2", text:"Batch 2 - generate one result per every predefined style (12 minutes)" },
  { value:"3", text:"Batch 2 - generate one result per each of the first 10 predefined styles" },
  { value:"4", text:"Batch 3 - generate one result per each of the second 10 predefined styles" },
  { value:"5", text:"Batch 4 - generate one result per each of the third 10 predefined styles" },
  { value:"6", text:"Batch 5 - generate one result per each of the fourth 10 predefined styles" },
  { value:"7", text:"Batch 6 - generate one result per each of the fifth 10 predefined styles" },
  { value:"8", text:"Batch 7 - generate one result per each of the sixth 10 predefined styles" },
  { value:"9", text:"Batch 8 - generate one result per each of the remaining predefined styles" },
];

export const ENHANCE_TYPES = [
  { value:"overall", text:"AI Overall Enhance" },
  { value:"face_restore", text:"AI Enhance with Face Restoration" },
  { value:"brighten", text:"Brighten" },
  { value:"detail", text:"Detail Enhance" },
  { value:"sharpen", text:"Sharpen" },
];

export const FILTER_MODE_OPTIONS = [
  { value:"0", text:"Single - generate one result for the selected filter" },
  { value:"1", text:"Batch 1 - generate one result per each of the first 10 filters" },
  { value:"2", text:"Batch 2 - generate one result per each of the second 10 filters" },
  { value:"3", text:"Batch 3 - generate one result per each of the third 10 filters" },
  { value:"4", text:"Batch 4 - generate one result per each of the fourth 10 filters" },
  { value:"5", text:"Batch 5 - generate one result per each of the remaining filters" },
];

export const FILTER_OPTIONS = [
  { value:"daylight", text:"Daylight" },
  { value:"warm", text:"Warm" },
  { value:"cool", text:"Cool" },
  { value:"sepia", text:"Sepia" },
  { value:"invert", text:"Invert" },
  { value:"color_mixup", text:"Color Mixup" },
  { value:"watercolor", text:"Watercolor" },
  { value:"pointillism", text:"Pointillism" },
  { value:"smooth", text:"Smooth" },
  { value:"blur", text:"Blur" },
  { value:"emboss", text:"Emboss" },
  { value:"high_contrast", text:"High Contrast" },
  { value:"ancient_tv", text:"Ancient TV" },
  { value:"duotone_red", text:"Duotone - Red" },
  { value:"duotone_lime", text:"Duotone - Lime" },
  { value:"duotone_blue", text:"Duotone - Blue" },
  { value:"duotone_yellow", text:"Duotone - Yellow" },
  { value:"duotone_aqua", text:"Duotone - Aqua" },
  { value:"duotone_magenta", text:"Duotone - Magenta" },
  { value:"duotone_olive", text:"Duotone - Olive" },
  { value:"duotone_green", text:"Duotone - Green" },
  { value:"duotone_purple", text:"Duotone - Purple" },
  { value:"duotone_teal", text:"Duotone - Teal" },
  { value:"duotone_gold", text:"Duotone - Gold" },
  { value:"duotone_coral", text:"Duotone - Coral" },
  { value:"duotone_orange", text:"Duotone - Orange" },
  { value:"duotone_pink", text:"Duotone - Pink" },
  { value:"duotone_lavender", text:"Duotone - Lavender" },
  { value:"duotone_violet", text:"Duotone - Violet" },
  { value:"duotone_gold_red", text:"Duotone - Gold Red" },
  { value:"duotone_coral_blue", text:"Duotone - Coral Blue" },
  { value:"colormap_spring", text:"Colormap - Spring" },
  { value:"colormap_summer", text:"Colormap - Summer" },
  { value:"colormap_autumn", text:"Colormap - Autumn" },
  { value:"colormap_winter", text:"Colormap - Winter" },
  { value:"colormap_jet", text:"Colormap - Jet" },
  { value:"colormap_rainbow", text:"Colormap - Rainbow" },
  { value:"colormap_ocean", text:"Colormap - Ocean" },
  { value:"colormap_bone", text:"Colormap - Bone" },
  { value:"colormap_cool", text:"Colormap - Cool" },
  { value:"colormap_hot", text:"Colormap - Hot" },
  { value:"colormap_hsv", text:"Colormap - HSV" },
  { value:"colormap_pink", text:"Colormap - Pink" },
  { value:"colormap_deepgreen", text:"Colormap - Deepgreen" },
  { value:"colormap_parula", text:"Colormap - Parula" },
  { value:"colormap_magma", text:"Colormap - Magma" },
  { value:"colormap_inferno", text:"Colormap - Inferno" },
  { value:"colormap_plasma", text:"Colormap - Plasma" },
  { value:"colormap_viridis", text:"Colormap - Viridis" },
  { value:"colormap_cividis", text:"Colormap - Cividis" },
  { value:"colormap_turbo", text:"Colormap - Turbo" },
  { value:"colormap_twilight", text:"Colormap - Twilight" },
  { value:"colormap_twilight_shifted", text:"Colormap - Twilight Shifted" },
];

export const SCALE_OPTIONS = [
  { value:"2", text:"2x" },
  { value:"3", text:"3x" },
  { value:"4", text:"4x" },
  { value:"8", text:"8x" },
];

export const SUPER_STYLE_OPTIONS = [
  { value:"unselected", text:"Select or enter a style description..." },
  { value:"a 3D render", text:"a 3D render" },
  { value:"a black and white photo", text:"a black and white photo" },
  { value:"a bronze sculpture", text:"a bronze sculpture" },
  { value:"a cartoon drawing", text:"a cartoon drawing" },
  { value:"a cubist painting", text:"a cubist painting" },
  { value:"a detailed matte painting", text:"a detailed matte painting" },
  { value:"a fine art painting", text:"a fine art painting" },
  { value:"a low poly render", text:"a low poly render" },
  { value:"a hyperrealistic painting", text:"a hyperrealistic painting" },
  { value:"a manga drawing", text:"a manga drawing" },
  { value:"a marble sculpture", text:"a marble sculpture" },
  { value:"a mosaic", text:"a mosaic" },
  { value:"a pastel art", text:"a pastel art" },
  { value:"a pencil sketch", text:"a pencil sketch" },
  { value:"a photorealistic painting", text:"a photorealistic painting" },
  { value:"a pointillism painting", text:"a pointillism painting" },
  { value:"a pop art painting", text:"a pop art painting" },
  { value:"a psychedelic drawing", text:"a psychedelic drawing" },
  { value:"a raytraced image", text:"a raytraced image" },
  { value:"a renaissance painting", text:"a renaissance painting" },
  { value:"a statue", text:"a statue" },
  { value:"a still life", text:"a still life" },
  { value:"a storybook illustration", text:"a storybook illustration" },
  { value:"a surrealist painting", text:"a surrealist painting" },
  { value:"a surrealist sculpture", text:"a surrealist sculpture" },
  { value:"a traditional Chinese painting", text:"a traditional Chinese painting" },
  { value:"a video game character", text:"a video game character" },
  { value:"a watercolor painting", text:"a watercolor painting" },
  { value:"an acrylic painting", text:"an acrylic painting" },
  { value:"an anime drawing", text:"an anime drawing" },
  { value:"an art deco painting", text:"an art deco painting" },
  { value:"an impressionist painting", text:"an impressionist painting" },
  { value:"an oil on canvas painting", text:"an oil on canvas painting" },
  { value:"concept art", text:"concept art" },
  { value:"cyberpunk art", text:"cyberpunk art" },
  { value:"egyptian art", text:"egyptian art" },
  { value:"graffiti art", text:"graffiti art" },
  { value:"pixel art", text:"pixel art" },
  { value:"poster art", text:"poster art" },
  { value:"steampunk art", text:"steampunk art" },
  { value:"Alan Bean", text:"Alan Bean (space, personality, sci-fi)" },
  { value:"Aleksi Briclot", text:"Aleksi Briclot (fantasy, dark)" },
  { value:"Alex Grey", text:"Alex Grey (psychedelic, colorful, detailed, vibrant)" },
  { value:"Android Jones", text:"Android Jones (detailed, colorful, fantasy, psychedelic, vibrant)" },
  { value:"Anna Dittmann", text:"Anna Dittmann (digital, portrait, fantasy)" },
  { value:"Beatrix Potter", text:"Beatrix Potter (whimsical, watercolor, children's illustration)" },
  { value:"Bill Carman", text:"Bill Carman (fantasy, pop art, surrealism)" },
  { value:"Brian Froud", text:"Brian Froud (fantasy, dark, whimsical)" },
  { value:"Carl Barks", text:"Carl Barks (cartoon, 3D)" },
  { value:"Carrie Ann Baade", text:"Carrie Ann Baade (detailed, surrealism, pop art)" },
  { value:"Chesley Bonestell", text:"Chesley Bonestell (sci-fi, space)" },
  { value:"Chris Foss", text:"Chris Foss (vibrant, sci-fi, space)" },
  { value:"Don Bergland", text:"Don Bergland (surrealism)" },
  { value:"Emily Balivet", text:"Emily Balivet (art nouveau, colorful, detailed)" },
  { value:"Evgeni Gordiets", text:"Evgeni Gordiets (surrealism, landscape, scene)" },
  { value:"Gabriele Dell’otto", text:"Gabriele Dell’otto (comics, fantasy)" },
  { value:"George Condo", text:"George Condo (cubism, surrealism, abstract)" },
  { value:"Giorgio de Chirico", text:"Giorgio de Chirico (surrealism, brutalism)" },
  { value:"Greg Rutkowski", text:"Greg Rutkowski (fantasy, surrealism)" },
  { value:"Gris Grimly", text:"Gris Grimly (dark, whimsical, children's illustration)" },
  { value:"Jasmine Becket-Griffith", text:"Jasmine Becket-Griffith (fantasy, portrait, whimsical, vibrant)" },
  { value:"Jim Burns", text:"Jim Burns (sci-fi, scene, character)" },
  { value:"Jody Bergsma", text:"Jody Bergsma (watercolor, fantasy, detailed, whimsical)" },
  { value:"John T. Biggers", text:"John T. Biggers (melanin, abstract, symbolism, vibrant)" },
  { value:"Lori Earley", text:"Lori Earley (portrait, figurativism, surrealism)" },
  { value:"Michael Hutter", text:"Michael Hutter (surrealism, detailed, sci-fi, fantasy)" },
  { value:"Minjae Lee", text:"Minjae Lee (portrait, surrealism)" },
  { value:"Naoto Hattori", text:"Naoto Hattori (surrealism, detailed)" },
  { value:"Ralph Bakshi", text:"Ralph Bakshi (cartoon)" },
  { value:"Ryohei Hase", text:"Ryohei Hase (portrait, surrealist, dark)" },
  { value:"Sandro Botticelli", text:"Sandro Botticelli (renaissance)" },
  { value:"Sophie Anderson", text:"Sophie Anderson (portrait, character)" },
  { value:"Susan Seddon Boulet", text:"Susan Seddon Boulet (fantasy, magic realism)" },
  { value:"Thomas Hart Benton", text:"Thomas Hart Benton (realism, scene)" },
  { value:"Ton Dubbeldam", text:"Ton Dubbeldam (pointillism, luminism, landscape, vibrant)" },
  { value:"Tristan Eaton", text:"Tristan Eaton (graphic design, street art, vollage, vibrant)" },
  { value:"Vincent Di Fate", text:"Vincent Di Fate (sci-fi, fantasy, space)" },
  { value:"<dreamy-painting>", text:"<dreamy-painting> (a textual inversion style)" },
  { value:"<fairy-tale-painting-style>", text:"<fairy-tale-painting-style> (a textual inversion style)" },
  { value:"<hanfu-anime-style>", text:"<hanfu-anime-style> (a textual inversion style)" },
  { value:"<iridescent-photo-style>", text:"<iridescent-photo-style> (a textual inversion style)" },
  { value:"<marbling-art>", text:"<marbling-art> (a textual inversion style)" },
  { value:"<midjourney-style>", text:"<midjourney-style> (a textual inversion style)" },
  { value:"<neon-pastel>", text:"<neon-pastel> (a textual inversion style)" },
  { value:"<new-priest>", text:"<new-priest> (a textual inversion style)" },
  { value:"<rick-art>", text:"<rick-art> (a textual inversion style)" },
  { value:"a cinematic fantasy landscape", text:"a cinematic fantasy landscape" },
  { value:"a field of flowers", text:"a field of flowers" },
  { value:"a flemish Baroque", text:"a flemish Baroque" },
  { value:"a little fairy town", text:"a little fairy town" },
  { value:"a Medieval fantasy", text:"a Medieval fantasy" },
  { value:"a misty mountain landscape photography", text:"a misty mountain landscape photography" },
  { value:"a portal towards other dimension", text:"a portal towards other dimension" },
  { value:"a psychedelic fantasy castle", text:"a psychedelic fantasy castle" },
  { value:"a secret flower garden", text:"a secret flower garden" },
  { value:"a solarpunk painting of an alien civilization", text:"a solarpunk painting of an alien civilization" },
  { value:"a whimsical fairy wonderland", text:"a whimsical fairy wonderland" },
  { value:"aurora borealis", text:"aurora borealis" },
  { value:"city on the moon", text:"city on the moon" },
  { value:"fireworks over the water", text:"fireworks over the water" },
  { value:"jungle waterfall", text:"jungle waterfall" },
  { value:"Mount Olympus at dawn", text:"Mount Olympus at dawn" },
  { value:"post apocalyptic wonderland", text:"post apocalyptic wonderland" },
  { value:"sunrise over the mountains", text:"sunrise over the mountains" },
  { value:"sunset over the bay", text:"sunset over the bay" },
  { value:"tropical beach", text:"tropical beach" },
  { value:"winter wonderland", text:"winter wonderland" },
];

export const IMG2IMG_MODE_OPTIONS = [
  { value:"0", text:"No" },
  { value:"1", text:"Yes - as style" },
  { value:"2", text:"Yes - as background" },
  { value:"3", text:"Yes - as style but keep structure with ControlNet (Canny)" },
  { value:"4", text:"Yes - as style but keep structure with ControlNet (Sketch)" },
  { value:"5", text:"Yes - as style but keep structure with ControlNet (Depth)" },
];

export const EMBEDDING_TYPES = [
  { value:"style", text:"Textual Inversion Styles" },
  { value:"object", text:"Textual Inversion Objects" },
];

export const DISPLAY_MODES = [
  { value:"1", text:"Normal" },
  { value:"2", text:"Concise (hide top descriptions and demo images/videos)" },
];

export const COMMUNICATION_PREFERENCES = [
  { value:"3", text:"Contact me on all significant events related to the app and/or me" },
  { value:"2", text:"Contact me for essential issues, important app updates and special offers (less)" },
  { value:"1", text:"Contact me for essential issues such as solving my support cases (least)" },
];

export const BACKGROUND_EDITOR_ACTIONS = [
  { value:"0", text:"Remove background" },
  { value:"1", text:"Replace background with an image" },
  { value:"2", text:"Replace background with a video" },
];

export const FOREGROUND_LOCATIONS = [
  { value:"0", text:"Top left" },
  { value:"1", text:"Top center" },
  { value:"2", text:"Top right" },
  { value:"3", text:"Center left" },
  { value:"4", text:"Center" },
  { value:"5", text:"Center right" },
  { value:"6", text:"Bottom left" },
  { value:"7", text:"Bottom center" },
  { value:"8", text:"Bottom right" },
];

export const GIF_MODES = [
  { value:"0", text:"No" },
  { value:"1", text:"Yes - fade in foreground" },
  { value:"2", text:"Yes - fade out foreground" },
  { value:"3", text:"Yes - fade in and out foreground" },
  { value:"4", text:"Yes - fade out and in foreground" },
  { value:"5", text:"Yes - fade in background" },
  { value:"6", text:"Yes - fade out background" },
  { value:"7", text:"Yes - fade in and out background" },
  { value:"8", text:"Yes - fade out and in background" },
];

export const GENDER_OPTIONS = [
  { value:"", text:"All" },
  { value:"male", text:"Male" },
  { value:"female", text:"Female" },
];

export const PRESENTER_SHAPE_OPTIONS = [
  { value:"original", text:"Original" },
  { value:"original_rembg", text:"Original with background removed" },
  { value:"circle", text:"Circle" },
  { value:"square", text:"Square" },
  { value:"hexagon", text:"Hexagon" },
  { value:"cloud", text:"Cloud" },
];


export const APP_NAME = "aiTransformer";
export const YOUTUBE_CHANNEL = "https://youtube.com/@aitransformer";   //https://www.youtube.com/channel/UCzLkrS60ZPwAnYY1ak8FX-A
export const UPLOAD_TO_PROCESS = "Upload to Process";
export const TOTAL_FILE_SIZE = 30 * 1024 * 1024;
export const MAX_VIDEO_FILE_SIZE = 100 * 1024 * 1024;
//export const MAX_VIDEO_FILE_LENGTH = 300;
export const MAX_AUDIO_FILE_SIZE = 10 * 1024 * 1024;
export const MAX_DOCUMENT_FILE_SIZE = 10 * 1024 * 1024;
export const MAX_WORDS_SHORT = 50;
export const MAX_WORDS_REGULAR = 150;

export const SUBSCRIPTION_PLANS = [
  { id: "0", title: "Free" },
  { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN1_ID, title: "Lite" },
  { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN2_ID, title: "Regular" },
  { id: process.env.REACT_APP_PAYPAL_SUBSCRIPTIPON_PLAN3_ID, title: "Pro" },
];

export const HELP_VIDEO_OPTIONS = [
  { key: '1', title: 'How to apply cartoon/filter effects to videos and transcribe videos', url: 'https://www.youtube.com/embed/xj3DbCu5NW8' },
  { key: '2', title: 'How to make dynamic explainer videos from recordings without voice', url: 'https://www.youtube.com/embed/Y4VdSLzUV-4' },
  { key: '3', title: 'How to turn documents into dynamic presentations', url: 'https://www.youtube.com/embed/P8AptX2hT_M' },
  { key: '4', title: 'How to create personalized talking greeting cards', url: 'https://www.youtube.com/embed/3m_CHYOGnLo' },
  { key: '5', title: 'How to combine pictures and words into story-telling videos', url: 'https://www.youtube.com/embed/riiaFHEgewM' },
  { key: '6', title: 'How to dub a movie', url: 'https://www.youtube.com/embed/dYeVWwKsmJk' },
];

export const GENERATION_METHOD_OPTIONS = [
  { value:"prompt", text:"Generate story from a prompt (enter the prompt)" },
  { value:"url", text:"Generate story based on the contents in a web page (paste the URL)" },
  { value:"document", text:"Generate story based on the contents in a document (upload the document, max size: 10 MB)" },
  { value:"custom", text:"Generate voice with supplied text and pictures with custom prompts (enter the custom story)" },
];

export const STORY_GENRE_OPTIONS = [
  { value:"", text:"undefined" },
  { value:"picture books", text:"picture books (children ages 0-5)" },
  { value:"early chapter books", text:"early chapter books (children ages 6-8)" },
  { value:"middle grade novels", text:"middle grade novels (children ages 9-12)" },
  { value:"young adult fiction", text:"young adult fiction" },
  { value:"realist literature", text:"realist literature" },
  { value:"literary fiction", text:"literary fiction" },
  { value:"science fiction", text:"science fiction" },
  { value:"fantasy", text:"fantasy" },
  { value:"mystery/thriller", text:"mystery/thriller" },
  { value:"romance", text:"romance" },
  { value:"horror", text:"horror" },
  { value:"historical fiction", text:"historical fiction" },
  { value:"adventure", text:"adventure" },
  { value:"drama", text:"drama" },
  { value:"comedy/satire", text:"comedy/satire" },
  { value:"TL;DR", text:"TL;DR" },
  { value:"first person", text:"first person" },
];

export const PICTURE_STYLE_OPTIONS = [
  { value:"base", text:"base" },
  { value:"cb-cartoon", text:"children's book - cartoon style illustrations" },
  { value:"cb-realistic", text:"children's book - realistic style illustrations" },
  { value:"cb-wimmelbuch", text:"children's book - wimmelbuch style illustrations" },
  { value:"cb-whimsical", text:"children's book - whimsical style illustrations" },
  { value:"cb-abstract", text:"children's book - abstract style illustrations" },
  { value:"cb-moody", text:"children's book - moody style illustrations" },
  { value:"cb-sketch", text:"children's book - line drawing and sketch style illustrations" },
  { value:"cb-vintage", text:"children's book - vintage style illustrations" },
  { value:"cb-manga", text:"children's book - manga style illustrations" },
  { value:"ads-advertising", text:"ads - advertising" },
  { value:"ads-automotive", text:"ads - automotive" },
  { value:"ads-corporate", text:"ads - corporate" },
  { value:"ads-fashion editorial", text:"ads - fashion editorial" },
  { value:"ads-food photography", text:"ads - food photography" },
  //{ value:"ads-gourmet food photography", text:"ads - gourmet food photography" },
  { value:"ads-luxury", text:"ads - luxury" },
  { value:"ads-real estate", text:"ads - real estate" },
  { value:"ads-retail", text:"ads - retail" },
  { value:"artstyle-abstract", text:"artstyle - abstract" },
  { value:"artstyle-abstract expressionism", text:"artstyle - abstract expressionism" },
  { value:"artstyle-art deco", text:"artstyle - art deco" },
  { value:"artstyle-art nouveau", text:"artstyle - art nouveau" },
  { value:"artstyle-constructivist", text:"artstyle - constructivist" },
  { value:"artstyle-cubist", text:"artstyle - cubist" },
  { value:"artstyle-expressionist", text:"artstyle - expressionist" },
  { value:"artstyle-graffiti", text:"artstyle - graffiti" },
  { value:"artstyle-hyperrealism", text:"artstyle - hyperrealism" },
  { value:"artstyle-impressionist", text:"artstyle - impressionist" },
  { value:"artstyle-pointillism", text:"artstyle - pointillism" },
  { value:"artstyle-pop art", text:"artstyle - pop art" },
  { value:"artstyle-psychedelic", text:"artstyle - psychedelic" },
  { value:"artstyle-renaissance", text:"artstyle - renaissance" },
  { value:"artstyle-steampunk", text:"artstyle - steampunk" },
  { value:"artstyle-surrealist", text:"artstyle - surrealist" },
  { value:"artstyle-typography", text:"artstyle - typography" },
  { value:"artstyle-watercolor", text:"artstyle - watercolor" },
  { value:"futuristic-biomechanical", text:"futuristic - biomechanical" },
  { value:"futuristic-biomechanical cyberpunk", text:"futuristic - biomechanical cyberpunk" },
  { value:"futuristic-cybernetic", text:"futuristic - cybernetic" },
  { value:"futuristic-cybernetic robot", text:"futuristic - cybernetic robot" },
  { value:"futuristic-cyberpunk cityscape", text:"futuristic - cyberpunk cityscape" },
  { value:"futuristic-futuristic", text:"futuristic - futuristic" },
  { value:"futuristic-retro cyberpunk", text:"futuristic - retro cyberpunk" },
  { value:"futuristic-retro futurism", text:"futuristic - retro futurism" },
  { value:"futuristic-sci-fi", text:"futuristic - sci-fi" },
  { value:"futuristic-vaporwave", text:"futuristic - vaporwave" },
  { value:"game-bubble bobble", text:"game - bubble bobble" },
  { value:"game-cyberpunk game", text:"game - cyberpunk game" },
  { value:"game-fighting game", text:"game - fighting game" },
  { value:"game-gta", text:"game - gta" },
  { value:"game-mario", text:"game - mario" },
  { value:"game-minecraft", text:"game - minecraft" },
  { value:"game-pokemon", text:"game - pokemon" },
  { value:"game-retro arcade", text:"game - retro arcade" },
  { value:"game-retro game", text:"game - retro game" },
  { value:"game-rpg fantasy game", text:"game - rpg fantasy game" },
  { value:"game-strategy game", text:"game - strategy game" },
  { value:"game-streetfighter", text:"game - streetfighter" },
  { value:"game-zelda", text:"game - zelda" },
  //{ value:"misc-architectural", text:"misc - architectural" },
  { value:"misc-disco", text:"misc - disco" },
  { value:"misc-dreamscape", text:"misc - dreamscape" },
  { value:"misc-dystopian", text:"misc - dystopian" },
  { value:"misc-fairy tale", text:"misc - fairy tale" },
  { value:"misc-gothic", text:"misc - gothic" },
  { value:"misc-grunge", text:"misc - grunge" },
  { value:"misc-horror", text:"misc - horror" },
  { value:"misc-kawaii", text:"misc - kawaii" },
  { value:"misc-lovecraftian", text:"misc - lovecraftian" },
  { value:"misc-macabre", text:"misc - macabre" },
  { value:"misc-manga", text:"misc - manga" },
  { value:"misc-metropolis", text:"misc - metropolis" },
  { value:"misc-minimalist", text:"misc - minimalist" },
  { value:"misc-monochrome", text:"misc - monochrome" },
  { value:"misc-nautical", text:"misc - nautical" },
  { value:"misc-space", text:"misc - space" },
  { value:"misc-stained glass", text:"misc - stained glass" },
  //{ value:"misc-techwear fashion", text:"misc - techwear fashion" },
  { value:"misc-tribal", text:"misc - tribal" },
  { value:"misc-zentangle", text:"misc - zentangle" },
  { value:"papercraft-collage", text:"papercraft - collage" },
  { value:"papercraft-flat papercut", text:"papercraft - flat papercut" },
  { value:"papercraft-kirigami", text:"papercraft - kirigami" },
  { value:"papercraft-paper mache", text:"papercraft - paper mache" },
  { value:"papercraft-paper quilling", text:"papercraft - paper quilling" },
  { value:"papercraft-papercut collage", text:"papercraft - papercut collage" },
  { value:"papercraft-papercut shadow box", text:"papercraft - papercut shadow box" },
  { value:"papercraft-stacked papercut", text:"papercraft - stacked papercut" },
  { value:"papercraft-thick layered papercut", text:"papercraft - thick layered papercut" },
  { value:"photo-alien", text:"photo - alien" },
  { value:"photo-film noir", text:"photo - film noir" },
  { value:"photo-glamour", text:"photo - glamour" },
  { value:"photo-hdr", text:"photo - hdr" },
  { value:"photo-iphone photographic", text:"photo - iphone photographic" },
  { value:"photo-long exposure", text:"photo - long exposure" },
  //{ value:"photo-neon noir", text:"photo - neon noir" },
  { value:"photo-silhouette", text:"photo - silhouette" },
  { value:"photo-tilt-shift", text:"photo - tilt-shift" },
  { value:"sai-3d-mode", text:"sai - 3d-mode" },
  { value:"sai-analog film", text:"sai - analog film" },
  { value:"sai-anime", text:"sai - anime" },
  { value:"sai-cinematic", text:"sai - cinematic" },
  { value:"sai-comic book", text:"sai - comic book" },
  { value:"sai-craft clay", text:"sai - craft clay" },
  { value:"sai-digital art", text:"sai - digital art" },
  { value:"sai-enhance", text:"sai - enhance" },
  { value:"sai-fantasy art", text:"sai - fantasy art" },
  { value:"sai-isometric", text:"sai - isometric" },
  { value:"sai-line art", text:"sai - line art" },
  { value:"sai-lowpoly", text:"sai - lowpoly" },
  { value:"sai-neonpunk", text:"sai - neonpunk" },
  { value:"sai-origami", text:"sai - origami" },
  { value:"sai-photographic", text:"sai - photographic" },
  { value:"sai-pixel art", text:"sai - pixel art" },
  { value:"sai-texture", text:"sai - texture" },
  { value:"artist: Amanda Clark", text:"artist: Amanda Clark" },
  { value:"artist: Anna Bocek", text:"artist: Anna Bocek" },
  { value:"artist: Anna Dittmann", text:"artist: Anna Dittmann" },
  { value:"artist: Aron Demetz", text:"artist: Aron Demetz" },
  { value:"artist: Carl Barks", text:"artist: Carl Barks" },
  { value:"artist: Chris Dyer", text:"artist: Chris Dyer" },
  { value:"artist: Christopher Bucklow", text:"artist: Christopher Bucklow" },
  { value:"artist: Cliff Chiang", text:"artist: Cliff Chiang" },
  { value:"artist: Daniel Arsham", text:"artist: Daniel Arsham" },
  { value:"artist: Daniel Clowes", text:"artist: Daniel Clowes" },
  { value:"artist: David Aja", text:"artist: David Aja" },
  { value:"artist: Etam Cru", text:"artist: Etam Cru" },
  { value:"artist: Eyvind Earle", text:"artist: Eyvind Earle" },
  { value:"artist: Fritz Eichenberg", text:"artist: Fritz Eichenberg" },
  { value:"artist: Giorgio de Chirico", text:"artist: Giorgio de Chirico" },
  { value:"artist: Harold Edgerton", text:"artist: Harold Edgerton" },
  { value:"artist: Hsiao Ron Cheng", text:"artist: Hsiao Ron Cheng" },
  { value:"artist: Jessica Durrant", text:"artist: Jessica Durrant" },
  { value:"artist: Lois van Baarle", text:"artist: Lois van Baarle" },
  { value:"artist: Lori Earley", text:"artist: Lori Earley" },
  { value:"artist: Peter Elson", text:"artist: Peter Elson" },
  { value:"artist: PJ Crook", text:"artist: PJ Crook" },
  { value:"artist: Rachel Bingaman", text:"artist: Rachel Bingaman" },
  { value:"artist: Robert Crumb", text:"artist: Robert Crumb" },
  { value:"artist: Ron English", text:"artist: Ron English" },
  { value:"artist: Sergio Aragonés", text:"artist: Sergio Aragonés" },
  { value:"artist: Terry Dodson", text:"artist: Terry Dodson" },
  { value:"artist: Tim Burton", text:"artist: Tim Burton" },
  { value:"artist: Tom Dixon", text:"artist: Tom Dixon" },
  { value:"artist: Tristan Eaton", text:"artist: Tristan Eaton" },
  { value:"artist: Will Eisner", text:"artist: Will Eisner" },
  { value:"artist: Wim Delvoye", text:"artist: Wim Delvoye" },
  { value:"movie: 300", text:"movie: '300' directed by Zack Snyder" },
  { value:"movie: A.I.", text:"movie: 'A.I.' directed by Steven Spielberg" },
  { value:"movie: Akira", text:"movie: 'Akira' directed by Katsuhiro Otomo" },
  { value:"movie: Avatar", text:"movie: 'Avatar' directed by James Cameron" },
  { value:"movie: Blue Velvet", text:"movie: 'Blue Velvet' directed by David Lynch" },
  { value:"movie: Brazil", text:"movie: 'Brazil' directed by Terry Gilliam" },
  { value:"movie: The Cell", text:"movie: 'The Cell' directed by Tarsem Singh" },
  { value:"movie: Dark City", text:"movie: 'Dark City' directed by Alex Proyas" },
  { value:"movie: Django Unchained", text:"movie: 'Django Unchained' directed by Quentin Tarantino" },
  { value:"movie: Dune (2021)", text:"movie: 'Dune (2021)' directed by Denis Villeneuve" },
  { value:"movie: Ex Machina", text:"movie: 'Ex Machina' directed by Alex Garland" },
  { value:"movie: The Fall", text:"movie: 'The Fall' directed by Tarsem Singh" },
  { value:"movie: The Grand Budapest Hotel", text:"movie: 'The Grand Budapest Hotel' directed by Wes Anderson" },
  { value:"movie: Hero", text:"movie: 'Hero' directed by Zhang Yimou" },
  { value:"movie: Life of Pi", text:"movie: 'Life of Pi' directed by Ang Lee" },
  { value:"movie: The Lord of the Rings", text:"movie: 'The Lord of the Rings' directed by Peter Jackson" },
  { value:"movie: Mad Max - Fury Road", text:"movie: 'Mad Max - Fury Road' directed by George Miller" },
  { value:"movie: The Matrix", text:"movie: 'The Matrix' directed by the Wachowskis" },
  { value:"movie: Melancholia", text:"movie: 'Melancholia' directed by Lars von Trier" },
  { value:"movie: Parasite", text:"movie: 'Parasite' directed by Bong Joon Ho" },
  { value:"movie: Sin City", text:"movie: 'Sin City' directed by Robert Rodriguez and Frank Miller" },
  { value:"movie: Spider-Man - Into the Spider-Verse", text:"movie: 'Spider-Man - Into the Spider-Verse'" },
  { value:"movie: Spirited Away", text:"movie: 'Spirited Away' directed by Hayao Miyazaki" },
  { value:"movie: Tron Legacy", text:"movie: 'Tron Legacy' directed by Joseph Kosinski" },
  { value:"movie: V for Vendetta", text:"movie: 'V for Vendetta' directed by James McTeigue" },
  { value:"custom", text:"*** Custom Style ***" },
];

export const PICTURE_RESOLUTION_OPTIONS = [
  { value:"1", text:"1920 x 1080 (YouTube, LinkedIn)" },
  { value:"2", text:"1080 x 1920 (YouTube Shorts, Instagram, TikTok, Snapchat, LinkedIn)" },
  { value:"3", text:"1280 x 720 (YouTube, Facebook, X, LinkedIn)" },
  { value:"4", text:"1080 x 1080 (YouTube, Facebook, Instagram, LinkedIn)" },
  { value:"5", text:"720 x 1280 (Facebook, X, LinkedIn)" },
  { value:"6", text:"720 x 720 (X, LinkedIn)" },
  { value:"7", text:"1024 x 768" },
  { value:"8", text:"1536 x 1152" },
];


