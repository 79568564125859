import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';
import HashMap from 'hashmap';
import { RangeStepInput } from 'react-range-step-input';

import UploadService from "../services/file-upload-storybook-maker.service";
import { Context } from "../Context";
import { resizeImage, getSpecialMessageHTML, isUrlValid, getResultsFolder } from "../utils";
import { APP_NAME, MAX_DOCUMENT_FILE_SIZE, MAX_AUDIO_FILE_SIZE, SERVER_API_BASE_URL, MAX_WORDS_SHORT, MAX_WORDS_REGULAR, 
  GENERATION_METHOD_OPTIONS, STORY_GENRE_OPTIONS, PICTURE_STYLE_OPTIONS, PICTURE_RESOLUTION_OPTIONS, GENDER_OPTIONS, PRESENTER_SHAPE_OPTIONS } from "../constants";
import { PopupShare } from "./Share/share.component";

const StorybookMaker = (props) => {
  const customValue = 'custom';
  const { dynamicText, authenticated, sid, locale, user, ttsLanguages, ttsVoices, defaultPresenters, backgroundImages } = useContext(Context);
  const [generationMethod, setGenerationMethod] = useState("prompt");
  const [prompt, setPrompt] = useState(undefined);
  const [url, setUrl] = useState(undefined);
  const [documentFile, setDocumentFile] = useState(undefined);
  const [customStory, setCustomStory] = useState(undefined);
  const [genre, setGenre] = useState("");
  const [pictureStyle, setPictureStyle] = useState("base");
  const [pictureResolution, setPictureResolution] = useState("1");
  const [customStyle, setCustomStyle] = useState(undefined);
  const [selectedVoice, setSelectedVoice] = useState(undefined);
  const [voiceSampleUrl, setVoiceSampleUrl] = useState(undefined);
  const [inputAudio, setInputAudio] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [resultsFolder, setResultsFolder] = useState(undefined);
  const [fileInfos, setFileInfos] = useState([]);
  const [useCustomVoice, setUseCustomVoice] = useState(false);
  const [usePresenter, setUsePresenter] = useState(false);
  const [selectedPresenter, setSelectedPresenter] = useState(customValue);
  const [presenterFile, setPresenterFile] = useState(undefined);
  const [previewPresenterImage, setPreviewPresenterImage] = useState(undefined);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("eng");
  const [adjustAudioProperties, setAdjustAudioProperties] = useState(false);
  const [audioPitch, setAudioPitch] = useState(1.0);
  const [audioSpeed, setAudioSpeed] = useState(1.0);
  const [audioVolume, setAudioVolume] = useState(0);
  const [presenterShape, setPresenterShape] = useState('circle');
  const [presenterLocation, setPresenterLocation] = useState(4);
  const [presenterHeight, setPresenterHeight] = useState(25);
  const [showLocationLegend, setShowLocationLegend] = useState(false);
  let timer = null;

  const languageMap = useRef();
  const languageOptions = useRef();
  const [voiceOptions, setVoiceOptions] = useState([]);
  const [presenterOptions, setPresenterOptions] = useState([]);
  
  // Sort the languages by code
  const compareLanguages = (a, b) => {
    if (a.name > b.name) return 1; 
    if (a.name < b.name) return -1; 
    return 0;
  }
  // Sort the voices by voice_id
  const compareVoices = (a, b) => {
    const key1 = `${9 - a.clonable}${a.voice_id}`
    const key2 = `${9 - b.clonable}${b.voice_id}`
    if (key1 > key2) return 1; 
    if (key1 < key2) return -1; 
    return 0;
  }

  useEffect(() => { 
    if (ttsLanguages.length > 0 && !languageMap.current) {
      languageMap.current = new HashMap();
      languageOptions.current = [];
      languageOptions.current.push({ value: "", text: "GENDER_OPTIONS_" });
      ttsLanguages.sort(compareLanguages).forEach(x => {
        languageMap.current.set(x.code, { "name": x.name, "test_text": x.test_text });
        languageOptions.current.push({ value: x.code, text: x.name });
      })
    }

    if (ttsVoices.length > 0 && languageMap.current && locale) {
      const options = [];
      ttsVoices.sort(compareVoices).forEach(v => {
        const voiceLanguage = v.voice_id.substring(0, 3);
        if ((!selectedGender || selectedGender === v.sex) && (!selectedLanguage || selectedLanguage === voiceLanguage)) {
          let detail = v.voice_id;
          detail += ' (' + intl.get(languageMap.current.get(voiceLanguage)?.name);
          if (v.sex)
            detail +=  ', ' + intl.get(v.sex);
          if (v.clonable) 
            detail +=  ', ' + intl.get(`clonable_${v.clonable}`);
          detail +=  ')';
          options.push({ value: v.voice_id, text: detail });
        }
      });
      setVoiceOptions(options);

      if (options && options.length > 0) {
        if (!selectedVoice) {
          const voice = options[0].value;
          setVoiceSampleUrl(new URL(SERVER_API_BASE_URL).origin + '/assets/voiceSamples/aiTransformer-synthesized_' + voice + '.mp3');
          setSelectedVoice(voice);
        }
      } else {
          setVoiceSampleUrl(undefined);
          setUseCustomVoice(false);
      }
    }

    if (ttsVoices.length > 0 && defaultPresenters.length > 0 && locale) {
      const options = [];
      options.push({ value: customValue, text: intl.get('custom_presenter_option') });
      let myPresenters;
      if (selectedGender) {
        myPresenters = defaultPresenters.filter(a => a.sex === selectedGender);
        if (!myPresenters.find(t => t.name === selectedPresenter.split(' ')[1]))  
          setPreviewPresenterImage(undefined);
      } else {
        myPresenters = defaultPresenters;
      }
      myPresenters.forEach(v => {
        options.push({ value: 'public ' + v.name, text: intl.get('public') + ' - ' + v.name });
      });
      setPresenterOptions(options);
    }

    if (resultsFolder !== undefined) {
      UploadService.getFiles(resultsFolder).then((response) => {
        setFileInfos(response.data);
      }); 
    }
  }, [ttsLanguages, ttsVoices, defaultPresenters, backgroundImages, selectedGender, selectedLanguage, selectedVoice, selectedPresenter, locale, resultsFolder]);

  const onChangeOfGenerationMethod = event => {
    const newMethod = event.target.value;
    setGenerationMethod(newMethod);
    if (newMethod === customValue) {
      setGenre('');
    }
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPrompt = event => {
    setPrompt(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfUrl = event => {
    setUrl(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const selectDocumentFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100 || currentFile.size > MAX_DOCUMENT_FILE_SIZE) {  //a too small file is probably invalid
      setDocumentFile(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
  
    setDocumentFile(currentFile);
    setProgress(0);
    setMessage("");
  }

  const onChangeOfCustomStory = event => {
    setCustomStory(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfGenre = event => {
    setGenre(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPictureStyle = event => {
    setPictureStyle(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPictureResolution = event => {
    setPictureResolution(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfCustomStyle = event => {
    setCustomStyle(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectedGender = event => {
    const newValue = event.target.value;
    setSelectedGender(newValue);
    if (newValue) {
      setSelectedVoice(undefined);
    }
    updatePresenterOptions(newValue);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectedLanguage = event => {
    const newValue = event.target.value;
    setSelectedLanguage(newValue);
    if (newValue) {
      setSelectedVoice(undefined);
    }
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectedVoice = event => {
    const newValue = event.target.value;
    setSelectedVoice(newValue);
    setVoiceSampleUrl(new URL(SERVER_API_BASE_URL).origin + '/assets/voiceSamples/aiTransformer-synthesized_' + newValue + '.mp3');
    setProgress(0);
    setMessage("");
  } 

  const updatePresenterOptions = (gender) => {
    setSelectedPresenter(customValue);
    setPreviewPresenterImage(undefined);
    if (defaultPresenters.length > 0 && locale) {
      presenterOptions.current = [];
      presenterOptions.current.push({ value: customValue, text: intl.get('custom_presenter_option') });
      let myPresenters;
      if (gender) {
        myPresenters = defaultPresenters.filter(a => a.sex === selectedGender);
      } else {
        myPresenters = defaultPresenters;
      }
      myPresenters.forEach(v => {
        presenterOptions.current.push({ value: 'public ' + v.name, text: intl.get('public') + ' - ' + v.name });
      });
    }
  } 

  const onChangeOfUseCustomVoice = event => {
    setUseCustomVoice(event.target.checked);
    setInputAudio(undefined);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAdjustAudioProperties = event => {
    setAdjustAudioProperties(event.target.checked);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioPitch = event => {
    setAudioPitch(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioSpeed = event => {
    setAudioSpeed(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioVolume = event => {
    setAudioVolume(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const selectInputAudio = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100 || currentFile.size > MAX_AUDIO_FILE_SIZE) {  //a too small file is probably invalid
      setInputAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
  
    const url = URL.createObjectURL(currentFile);
    const audio = new Audio(url);
    audio.onerror = () => {
      setInputAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
    audio.onloadedmetadata = () => {
      if (isNaN(audio.duration)) {   //not a valid audio
        setInputAudio(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
        return;
      }
    }

    setInputAudio(currentFile);
    setProgress(0);
    setMessage("");
  }

  const onChangeOfUsePresenter = event => {
    const isChecked = event.target.checked;
    setUsePresenter(isChecked);
    setProgress(0);
    setMessage("");
    if (!presenterOptions.current)
      updatePresenterOptions(selectedGender);
  } 

  const onChangeOfSelectPresenter = event => {
    const newPresenter = event.target.value;
    setSelectedPresenter(newPresenter);
    if (newPresenter === customValue) {
      setPresenterFile(undefined);
      setPreviewPresenterImage(undefined);
      setProgress(0);
      setMessage("");
      return;
    }
    const fileName = `${newPresenter}.jpg`;
    const imageUrl = `${SERVER_API_BASE_URL}/presenters/${newPresenter}`;
    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {
        const iamgeFile = new File([imageBlob], fileName);
        setPresenterFile(iamgeFile);
        setPreviewPresenterImage(URL.createObjectURL(iamgeFile));
        setProgress(0);
        setMessage("");
      });
  } 

  const selectPresenterFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        setPresenterFile(undefined);
        setPreviewPresenterImage(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));        
        return;
      }
    }
    setPresenterFile(currentFile);
    setPreviewPresenterImage(URL.createObjectURL(currentFile));
    setProgress(0);
    setMessage("");
  }

  const onChangeOfPresenterShape = event => {
    setPresenterShape(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPresenterLocation = event => {
    setPresenterLocation(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPresenterHeight = event => {
    setPresenterHeight(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const recoverResults = async () => {
    setProgress(1);
    const resultsDir = await getResultsFolder('userFolder4StorybookMaker');
    if (!resultsDir) {
      setMessage(intl.get("No_results"));
    } else {
      setResultsFolder(resultsDir);
    }
    setProgress(100);
  }

  const uploadAll = (files, prompt, url, documentIsPdf, story, style, voice, pitch, speed, volume, sid, locale) => {
    let duration = 3 * 60 * 1000;
    if (user.subscriptionPlanId)   //it runs longer for subscribers with greater story length
      duration *= 3;
    const startTime = Date.now();
    timer = null;
    setProgress(0);
    setMessage("");

    UploadService.getServerStatus().then((serverStatus) => {
      if (serverStatus < 0) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_UNAVAILABLE'));
      }
      else if (serverStatus < 1) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_BUSY'));
      }
      else UploadService.upload(files, generationMethod, prompt, url, documentIsPdf, story, genre, style, pictureResolution, voice, useCustomVoice, pitch, speed, volume, usePresenter, presenterShape, presenterLocation, presenterHeight, sid, locale, (event) => {
        if (timer === null) {
          //console.log('start upload and set timer');
          timer = setInterval(() => {
            const percentage = Math.round(((Date.now() - startTime) / duration) * 100);
            if (percentage > 96) 
              clearInterval(timer);
            else 
              setProgress(percentage);
          }, 500)
        }
      }).then((response) => {
        //console.log('upload scceeded and clear timer');
        clearInterval(timer);
        setMessage(response.data.message);
        setProgress(100);
        setResultsFolder(response.data.userFolder4StorybookMaker);
      })
      .catch((reason) => {
        console.log('upload error: ' + reason);
        clearInterval(timer);
        if (reason.code === 'ECONNABORTED') {
          // handle timeout error
          if (resultsFolder) {
            setProgress(100);
          } else {
            //setProgress(0);
            //setMessage(intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }));
            recoverResults();
            setProgress(100);
          }
        } else {
          // handle other errors
          if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
            setProgress(0);
            setMessage(intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }));
            setResultsFolder(reason.response.data.userFolder4StorybookMaker);
          } else {
            setProgress(0);
            setMessage(intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }));
          }
        }
      })
      .finally(() => {
        //console.log('returned from upload, resultsFolder = ' + resultsFolder);
        if (typeof resultsFolder !== "undefined")
          UploadService.getFiles(resultsFolder)
          .then((processedFiles) => {
            setFileInfos(processedFiles.data);
          })
      })
    });
  }

  const uploadToProcess = (sid, locale) => {
    let myprompt = undefined;
    if (generationMethod === 'prompt') {
      myprompt = prompt?.trim();
      if (!myprompt) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_PROMPT'));
        return;
      }
    }
    let myurl = undefined;
    if (generationMethod === 'url') {
      myurl = url?.trim();
      if (!myurl || !isUrlValid(myurl)) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_OR_INVALID_URL'));
        return;
      }
    }
    if (generationMethod === 'document' && !documentFile) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_DOCUMENT_FILE'));
      return;
    }
    let mystory = undefined;
    if (generationMethod === customValue) {
      mystory = customStory?.trim().replaceAll('{', '').replaceAll('}', '');
      if (!mystory) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_STORY'));
        return;
      }
    }
    let style = 'base';
    if (pictureStyle === customValue) {
      let mystyle = customStyle.trim().replaceAll('{', '').replaceAll('}', '');
      if (mystyle) {
        style = customValue + '#' + mystyle;
      }
    } else {
      style = pictureStyle;
    }
    let voice = selectedVoice;
    if (!voice && voiceOptions && voiceOptions.length > 0) {
      voice = voiceOptions[0].value;
    }
    if (useCustomVoice && !inputAudio) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_VOICE'));
      return;
    }
    if (usePresenter && (selectedPresenter === customValue) && !presenterFile) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_PRESENTER'));
      return;
    }
    let documentIsPdf = false;
    const selectedFiles = [];
    if (generationMethod === 'document' && documentFile) {
      selectedFiles.push(documentFile);
      documentIsPdf = documentFile['type'] === 'application/pdf';
    }
    if (useCustomVoice && inputAudio) {
      selectedFiles.push(inputAudio);
    }
    if (usePresenter && presenterFile) {
      selectedFiles.push(presenterFile);
    }
    let pitch = 1;
    let speed = 1;
    let volume = 0;
    if (adjustAudioProperties) {
      pitch = audioPitch;
      speed = audioSpeed;
      volume = audioVolume;
    }

    uploadAll(selectedFiles, myprompt, myurl, documentIsPdf, mystory, style, voice, pitch, speed, volume, sid, locale);
  }

  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);
  const ulClassName = props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
  //const demoFileName = 'StorybookMaker2_' + locale.substring(0, 2) + '.mp4';
  const sampleFileBaseName = new URL(SERVER_API_BASE_URL).origin + '/assets/storybookMakerSamples/' + APP_NAME + '-';

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Storybook_Maker') }</title>
        <meta name="description" content={ intl.get('StorybookMaker_info') } />
      </Helmet>
      <div className="title">{ intl.get('Storybook_Maker') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}

      {(!authenticated || user.displayMode === '1') && props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-4 align-self-center introduction">
            { intl.getHTML('StorybookMaker_description') } <br/>{ intl.getHTML('reserved_tool_note') }
          </div>
          <div className="col-8 text-center embed-responsive embed-responsive-16by9"> 
            <iframe width="560" height="315" src={dynamicText.storybookMakerVideo2} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      )}
      {(!authenticated || user.displayMode === '1') && !props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-12 text-center">
            <div className="introduction">
              { intl.getHTML('StorybookMaker_description') } <br/>{ intl.getHTML('reserved_tool_note') }
            </div>
            <div className="embed-responsive embed-responsive-16by9"> 
              <iframe width="560" height="315" src={dynamicText.storybookMakerVideo2} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>              
            </div>
          </div>
        </div>
      )}
        
      {!authenticated && (
        <div className="row highlight m-2">
          <div className="col-12">
            { intl.getHTML('Super_Stylizer_usage_quota_message') }
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-12">
          { intl.getHTML('StorybookMaker_instruction', {shortWords: MAX_WORDS_SHORT, regularWords: MAX_WORDS_REGULAR}) } { intl.get('download_results_message') }
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Story_Generation_Method') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfGenerationMethod}>
            {GENERATION_METHOD_OPTIONS.map(item => {
              return item.value === generationMethod ? 
              (<option key={item.value} value={item.value} selected>{ intl.get('GENERATION_METHOD_OPTIONS_' + item.value) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get('GENERATION_METHOD_OPTIONS_' + item.value) }</option>);
            })}
          </select>
          {(generationMethod === 'prompt') && (
            <textarea className="form-control" value={prompt} onChange={onChangeOfPrompt} />
          )}
          {(generationMethod === 'url') && (
            <input className="form-control" type="text" value={url} onChange={onChangeOfUrl} />
          )}
          {(generationMethod === 'document') && (
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                      application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, 
                      application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                      application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, 
                      application/pdf, application/rtf, application/xml, text/csv, text/html, text/plain" 
                      onChange={selectDocumentFile} />
            </label>
          )}
          {generationMethod === customValue && (
            <textarea className="form-control" value={customStory} placeholder={intl.get('Custom_Generation_Method_hint')} onChange={onChangeOfCustomStory} />
          )}
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Story_Genre') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfGenre} disabled={generationMethod === customValue}>
            {STORY_GENRE_OPTIONS.map(item => {
              return item.value === genre ? 
              (<option key={item.value} value={item.value} selected>{ intl.get('STORY_GENRE_OPTIONS_' + item.value) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get('STORY_GENRE_OPTIONS_' + item.value) }</option>);
            })}
          </select>
          </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Picture_Style') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfPictureStyle}>
            {PICTURE_STYLE_OPTIONS.map(item => {
              return item.value === pictureStyle ? 
              (<option key={item.value} value={item.value} selected>{item.text}</option>)
              : (<option key={item.value} value={item.value}>{item.text}</option>);
            })}
          </select>
          {pictureStyle === customValue && (
            <textarea className="form-control" value={customStyle} placeholder={intl.get('Custom_Style_hint')} onChange={onChangeOfCustomStyle} />
          )}
        </div>
      </div>
      
      {pictureStyle && pictureStyle !== customValue && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Style_Sample') }
          </div>
          <div className="col-md-9">
            <img className="img-fluid" src={sampleFileBaseName + pictureStyle.replaceAll(' ', '_').replaceAll(':', '_') + ".jpg"} alt={pictureStyle} />
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Picture_Resolution') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfPictureResolution}>
            {PICTURE_RESOLUTION_OPTIONS.map(item => {
              return item.value === pictureResolution ? 
              (<option key={item.value} value={item.value} selected>{item.text}</option>)
              : (<option key={item.value} value={item.value}>{item.text}</option>);
            })}
          </select>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Storyteller_Properties') }
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Gender') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedGender}>
            {GENDER_OPTIONS.map(item => {
              return item.value === selectedGender ? 
              (<option key={item.value} value={item.value} selected>{ intl.get('GENDER_OPTIONS_' + item.value) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get('GENDER_OPTIONS_' + item.value) }</option>);
            })}
          </select>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Language') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedLanguage} disabled>
            {languageOptions.current?.map(item => {
              return item.value === selectedLanguage ? 
              (<option key={item.value} value={item.value} selected>{ intl.get(item.text) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get(item.text) }</option>);
            })}
          </select>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Select_Voice') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedVoice}>
            {voiceOptions?.map(item => {
              return item.value === selectedVoice ? 
              (<option key={item.value} value={item.value} selected>{ item.text }</option>)
              : (<option key={item.value} value={item.value}>{ item.text }</option>);
            })}
          </select>
        </div>
      </div>
      
      {voiceSampleUrl && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Voice_Sample') }
          </div>
          <div className="col-md-9">
            <audio controls src={voiceSampleUrl} />
          </div>
        </div>
      )}
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Use_Custom_Voice') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={useCustomVoice} onChange={onChangeOfUseCustomVoice} />
        </div>
      </div>
      {useCustomVoice && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.getHTML('Voice_To_Clone', { size_in_mb: MAX_AUDIO_FILE_SIZE / 1048576 }) }
          </div>
          <div className="col-md-9">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="audio/*" onChange={selectInputAudio} />
            </label>
            {inputAudio && (
              <div>
                <audio controls src={URL.createObjectURL(inputAudio)} />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Adjust_Audio_Properties') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={adjustAudioProperties} onChange={onChangeOfAdjustAudioProperties} />
        </div>
      </div>
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Pitch') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={0.5} max={2.0} step={0.1} value={audioPitch} onChange={onChangeOfAudioPitch} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioPitch}x
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Speed') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={0.5} max={2.0} step={0.1} value={audioSpeed} onChange={onChangeOfAudioSpeed} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioSpeed}x
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Volume') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={-20} max={20} step={1} value={audioVolume} onChange={onChangeOfAudioVolume} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioVolume}db
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Use_Presenter_Tell_Story') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={usePresenter} onChange={onChangeOfUsePresenter} />
        </div>
      </div>
      {usePresenter && (
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Select_Presenter') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectPresenter}>
            {presenterOptions?.map(item => {
              return item.value === selectedPresenter ? 
              (<option key={item.value} value={item.value} selected>{ item.text }</option>)
              : (<option key={item.value} value={item.value}>{ item.text }</option>);
            })}
          </select>
          {(!selectedPresenter || selectedPresenter === customValue) && (
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="image/*" onChange={selectPresenterFile} />
            </label>
          )}
          {previewPresenterImage && (
            <div>
              <img className="preview" src={previewPresenterImage} alt="" />
            </div>
          )}
        </div>
      </div>
      )}

      {usePresenter &&  (
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Shape') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker" data-style="btn-success" onChange={onChangeOfPresenterShape}>
            {PRESENTER_SHAPE_OPTIONS.map(item => {
            return item.value === presenterShape ? 
            (<option key={item.value} value={item.value} selected>{ intl.get('PRESENTER_SHAPE_OPTIONS_' + item.value) }</option>)
            : (<option key={item.value} value={item.value}>{ intl.get('PRESENTER_SHAPE_OPTIONS_' + item.value) }</option>);
            })}
          </select>
        </div>
      </div>
      )}
      
      {usePresenter &&  (
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Location') }
        </div>
        <div className="col-md-9">
          <div className="input-group mb-3">
            <RangeStepInput min={1} max={16} step={1} value={presenterLocation} onChange={onChangeOfPresenterLocation} />
            <div className="input-group-append">
              <span className="input-group-text ml-2">
                {presenterLocation}
              </span>
            </div>&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="btn mb-3 mr-3 btn-outline-success"
              onClick={() => setShowLocationLegend(!showLocationLegend)}
            >
              { intl.get('Toggle_Show_Legend') }
            </button>
          </div>
          {showLocationLegend && (
            <div>
              <img src="/images/legendOfPresenterLocation.jpg" alt={intl.get("legendOfPresenterLocation")} className="img-fluid preview" />
            </div>
          )}
        </div>
      </div>
      )}
      
      {usePresenter &&  (
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Height') }&nbsp;{ intl.get('Presenter_Height_extra') }
        </div>
        <div className="col-md-9">
          <div className="input-group mb-3">
            <RangeStepInput min={10} max={90} step={1} value={presenterHeight} onChange={onChangeOfPresenterHeight} />
            <div className="input-group-append">
                <span className="input-group-text ml-2">
                  {presenterHeight}%
                </span>
            </div>
          </div>
        </div>
      </div>
      )}

      <div className="row m-2">
        <div className="col-6 text-center">
          <button
            className="btn mb-3 mr-3 btn-info btn-lg"
            disabled={!(authenticated && !progress)}
            onClick={() => uploadToProcess(sid, locale)}
          >
            { intl.get('UPLOAD_TO_PROCESS') }
          </button>
        </div>
        <div className="col-6 text-center">
          <button
            className="btn mb-3 mr-3 btn-info btn-lg"
            disabled={!authenticated || (progress > 0 && progress < 100)}
            onClick={() => recoverResults()}
          >
            { intl.get('Recover_Results') }
          </button>
        </div>
      </div>

      {authenticated && (
        <div className="progress m-2">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      {message && (
        <div className="alert alert-warning my-1 text-wrap" role="alert">
          {message}
        </div> 
      )}

      {fileInfos.length > 0 && (
        <div className="card">
          <div className="card-header alert alert-success text-center">
            <h4>
              { intl.get('Results') }&nbsp;&nbsp;&nbsp;&nbsp;
              <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
            </h4> 
            { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan4') }
          </div>
          <ul className={ulClassName}>
            {fileInfos.filter(x => x.url.slice(-3) === 'mp4').map((vid, index) => {
              const storyFile = fileInfos.filter(a => a.url.slice(-3) === 'txt' && vid.name.startsWith(a.name.slice(0, -4)))[0];
              return (<li className="list-group-item" key={index}>
                <div class="embed-responsive embed-responsive-4by3">
                  <video src={vid.url} width="320" height="240" controls /> 
                </div>
                <h6 className="card-title">{vid.name}&nbsp;&nbsp;&nbsp;&nbsp;<a href={vid.url}><b>{ intl.get('Download_video') }</b></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href={storyFile.url}><b>{ intl.get('Download_story') }</b></a></h6>
              </li>)
            })}
          </ul>
        </div>
      )}
    </motion.div>
  );
}

export default StorybookMaker;