import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import { RangeStepInput } from 'react-range-step-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

import UploadService from "../services/file-upload-super-stylizer.service";
import { Context } from "../Context";
import { resizeImage, getSpecialMessageHTML, getResultsFolder } from "../utils";
import { APP_NAME, SUPER_STYLE_OPTIONS, IMG2IMG_MODE_OPTIONS } from "../constants";
import { PopupShare } from "./Share/share.component";
import { PromptBuilderSamplesView } from './SamplesGallery/PromptBuilderSamplesView';
import { ControlNetSamplesView } from './SamplesGallery/ControlNetSamplesView';

export default class Upload4SuperStylizer extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectInputImage = this.selectInputImage.bind(this);
    this.onChangeOfSelectStyle = this.onChangeOfSelectStyle.bind(this);

    this.state = {
      styleDescription: undefined,
      negativePrompt: undefined,
      sampleStyleFileName: undefined,
      numberOfImagesToProduce: 4,
      seed: undefined,
      img2imgMode: '0',
      inputImage: undefined,
      previewInputImage: undefined,
      styleStrength: 50,
      progress: 0,
      message: "",

      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,

      isPromptBuilderSamplesModalOpen: false,
      isControlNetSamplesModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  showPromptBuilderSamplesModal = () => {
    this.setState({
      isPromptBuilderSamplesModalOpen: true,
    });
  };

  hidePromptBuilderSamplesModal = () => {
    this.setState({
      isPromptBuilderSamplesModalOpen: false,
    });
  };

  //reading from the clipboard is not supported in Firefox, see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/readText
  appendSelectedPrompts4PromptBuilder = async () => {
    const isFirefoxOrWebView  = /Firefox|wv/.test(navigator.userAgent);
    if (isFirefoxOrWebView) {  
      this.setState({
        isPromptBuilderSamplesModalOpen: false,
      });
      alert(intl.get('Paste_text_into_style_description'));
    } else {
      const clipText = await navigator.clipboard.readText();
      this.setState({
        isPromptBuilderSamplesModalOpen: false,
        styleDescription: this.state.styleDescription ? this.state.styleDescription + ', ' + clipText : clipText,
      });
    }
  };

  showControlNetSamplesModal = () => {
    this.setState({
      isControlNetSamplesModalOpen: true,
    });
  };

  hideControlNetSamplesModal = () => {
    this.setState({
      isControlNetSamplesModalOpen: false,
    });
  };

  appendSelectedPrompts4ControlNet = async () => {
    const isFirefoxOrWebView  = /Firefox|wv/.test(navigator.userAgent);
    if (isFirefoxOrWebView) {  
      this.setState({
        isControlNetSamplesModalOpen: false,
      });
      alert(intl.get('Paste_text_into_style_description'));
    } else {
      const clipText = await navigator.clipboard.readText();
      this.setState({
        isControlNetSamplesModalOpen: false,
        styleDescription: this.state.styleDescription ? this.state.styleDescription + ', ' + clipText : clipText,
      });
    }
  };

  onChangeOfSelectStyle = event => {
    if (event.target.value === "unselected") {
      this.setState({
        styleDescription: "",
        sampleStyleFileName: undefined,
        progress: 0,
        message: "",
      });
    } else {
      this.setState({
        styleDescription: event.target.value,
        sampleStyleFileName: event.target.value.replaceAll(' ', '_').replaceAll('<', '[').replaceAll('>', ']') + '.jpg',
        progress: 0,
        message: "",
      })
    }
  } 

  changeStyleDescription = event => {
    this.setState({
      styleDescription: event.target.value,
      sampleStyleFileName: undefined,
      progress: 0,
      message: "",
    });
  } 

  changeNegativePrompt = event => {
    this.setState({
      negativePrompt: event.target.value,
      progress: 0,
      message: "",
    });
  } 

  onChangeOfNumberOfImagesToProduce = event => {
    this.setState({
      numberOfImagesToProduce: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfSeed = event => {
    let value = event.target.value;
    if (value) {
      if (isNaN(value)) {
        this.setState({
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_SEED_INVALID'),
        });
        return;
      }
      value = parseInt(value);
    }
    this.setState({
      seed: value,
      progress: 0,
      message: "",
    });
  } 

  onChangeOfImg2imgMode = event => {
    if (event.target.value === "0") {
      this.setState({
        img2imgMode: event.target.value,
        inputImage: undefined,
        previewInputImage: undefined,
        progress: 0,
        message: "",
      });
    } else {
      this.setState({
        img2imgMode: event.target.value,
        progress: 0,
        message: "",
      })
    }
  } 

  selectInputImage = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          inputImage: undefined,
          previewInputImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      inputImage: currentFile,
      previewInputImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfStyleStrength = event => {
    this.setState({
      styleStrength: event.target.value,
      progress: 0,
      message: ""
    });
  }

  recoverResults = async () => {
    this.setState({progress: 1});
    const resultsDir = await getResultsFolder('userFolder4SuperStylizer');
    if (!resultsDir) {
      this.setState({message: intl.get("No_results")});
    } else {
      this.setState({resultsFolder: resultsDir});
      this.componentDidMount();
    }
    this.setState({progress: 100});
  }

  uploadAll(files, sid, locale) {
    this.timer = null;
    this.duration = 240 * 1000;
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.getServerStatus().then((serverStatus) => {
      if (serverStatus < 0) {
        this.setState({
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_SERVER_UNAVAILABLE'),
        });
      }
      else if (serverStatus < 1) {
        this.setState({
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_SERVER_BUSY'),
        });
      }
      else UploadService.upload(files, this.state.styleDescription, this.state.numberOfImagesToProduce, this.state.seed, this.state.styleStrength, sid, locale, this.state.negativePrompt, this.state.img2imgMode, (event) => {
        if (this.timer === null)
          this.timer = setInterval(() => {
            const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
            if (percentage > 96) 
              clearInterval(this.timer);
            else 
              this.setState({ 
                progress: percentage
              })
          }, 500)
      }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4SuperStylizer,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (reason.code === 'ECONNABORTED') {
          // handle timeout error
          if (this.state.resultsFolder) {
            this.setState({progress: 100});
          } else {
            //this.setState({
            //  progress: 0,
            //  message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
            //});
            this.recoverResults();
            this.setState({progress: 100});
          }
        } else {
          // handle other errors
          if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
            this.setState({
              progress: 0,
              message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
              resultsFolder: reason.response.data.userFolder4SuperStylizer,
            });
          } else {
            this.setState({
              progress: 0,
              message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
            });
          }
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
    });
  }

  uploadImages(sid, locale) {
    if (!this.state.styleDescription) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_STYLE_DESCRIPTION'),
      });
      return;
    }
    if (this.state.img2imgMode !== "0" && this.state.inputImage === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        const selectedFiles = [];
        selectedFiles.push(this.state.inputImage);
        this.uploadAll(selectedFiles, sid, locale);
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { isPromptBuilderSamplesModalOpen, isControlNetSamplesModalOpen, styleDescription, negativePrompt, sampleStyleFileName, numberOfImagesToProduce, seed, img2imgMode, 
      inputImage, previewInputImage, progress, message, styleStrength, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Super_Stylizer') }</title>
          <meta name="description" content={ intl.get('SuperStylizer_info') } />
        </Helmet>
        <div className="title">{ intl.get('Super_Stylizer') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && (
          <div className="row m-2">
            <div className="col-12 text-center">
              <img src="/images/superstylizer2.png" alt="Super Stylizer example 2" className="img-fluid" />
            </div>
          </div>
        )}

        {(!authenticated || user.displayMode === '1') && this.props.isLargeScreen && (
          <div className="row mx-1 my-4">
            <div className="col-5 align-self-center introduction">
               { intl.getHTML('Super_Stylizer_description') }
            </div>
            <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
              <iframe width="560" height="315" src={dynamicText.superStylizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
            </div>
          </div>
        )}
        {(!authenticated || user.displayMode === '1') && !this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('Super_Stylizer_description') }
              </div>
              <div className="embed-responsive embed-responsive-16by9"> 
                <iframe width="560" height="315" src={dynamicText.superStylizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
              </div>
            </div>
          </div>
        )}

        {!authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              { intl.getHTML('Super_Stylizer_usage_quota_message') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('Super_Stylizer_instruction') } { intl.get('download_results_message') }
          </div>
        </div>
        
        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('Super_Stylizer_usage_tips') }
          </div>
        </div>

        <div className="row m-2" />
        <div className="row mx-4 alert alert-info">
          <div className="col-md-6 text-center">
            <button className="btn mb-2 mr-2 btn-lg highlight1"
              onClick={this.showPromptBuilderSamplesModal} >
              { intl.get('Pick_Prompts_from') + ' ' + intl.get('PromptBuilder_Samples_Gallery') }
            </button>
          </div>
          <div className="col-md-6 text-center">
            <button className="btn mb-2 mr-2 btn-lg highlight1"
              onClick={this.showControlNetSamplesModal} >
              { intl.get('Pick_Prompts_from') + ' ' + intl.get('ControlNet_Samples_Gallery') }
            </button>
          </div>
        </div>
        <Modal show={isPromptBuilderSamplesModalOpen} onHide={this.hidePromptBuilderSamplesModal} size="xl" scrollable={true} style={{ height: "90%" }}>
          <Modal.Header>
            <Modal.Title>{ intl.get('PromptBuilder_Samples_Gallery') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>      
            <PromptBuilderSamplesView enableLightbox={false} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={this.appendSelectedPrompts4PromptBuilder}>{ intl.get('Append_Selected_Prompts') }</Button>
            <Button variant="dark" onClick={this.hidePromptBuilderSamplesModal}>{ intl.get('Close') }</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isControlNetSamplesModalOpen} onHide={this.hideControlNetSamplesModal} size="xl" scrollable={true} style={{ height: "90%" }}>
          <Modal.Header>
            <Modal.Title>{ intl.get('ControlNet_Samples_Gallery') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>      
            <ControlNetSamplesView enableLightbox={false} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={this.appendSelectedPrompts4ControlNet}>{ intl.get('Append_Selected_Prompts') }</Button>
            <Button variant="dark" onClick={this.hideControlNetSamplesModal}>{ intl.get('Close') }</Button>
          </Modal.Footer>
        </Modal>

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Style_Description') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfSelectStyle}>
              {SUPER_STYLE_OPTIONS.map(item => {
                return !['unselected'].includes(item.value) ? (<option key={item.value} value={item.value} defaultValue={styleDescription}>{item.text}</option>) :
                (<option key={item.value} value={item.value} defaultValue={styleDescription}>{ intl.get('SUPER_STYLE_OPTIONS_' + item.value) }</option>);
              })}
            </select>
            <div className="my-1">
              <textarea className="form-control" value={styleDescription} onChange={this.changeStyleDescription} />
            </div>
          </div>
        </div>
        
        {sampleStyleFileName && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Sample_Style_Image') }
            </div>
            <div className="col-md-9">
              <LazyLoadImage className="preview" src={"/images/" + sampleStyleFileName} alt={"a sample of " + sampleStyleFileName} />
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Negative_Prompt') }
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" value={negativePrompt} onChange={this.changeNegativePrompt} />
          </div>
        </div>
        
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Number_of_Outputs') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={1} max={10} step={1} value={numberOfImagesToProduce} onChange={this.onChangeOfNumberOfImagesToProduce} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {numberOfImagesToProduce}
                  </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Starting_Seed') }
          </div>
          <div className="col-md-9">
            <input type="text" value={seed} onChange={this.onChangeOfSeed} />
          </div>
        </div>

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Apply_Generated_Styles_To_Image') }
          </div>
          {(authenticated && user.subscriptionPlanId) ? (
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfImg2imgMode}>
                {IMG2IMG_MODE_OPTIONS.map(item => {
                  return (<option key={item.value} value={item.value} defaultValue={img2imgMode}>{ intl.get('IMG2IMG_MODE_OPTIONS_' + item.value) }</option>);
                })}
              </select>
            </div>
          ) : (
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfImg2imgMode}>
                {IMG2IMG_MODE_OPTIONS.filter(item => item.value < '3').map(item => {
                  return (<option key={item.value} value={item.value} defaultValue={img2imgMode}>{ intl.get('IMG2IMG_MODE_OPTIONS_' + item.value) }</option>);
                })}
              </select>
              <p>{ intl.getHTML('ControlNet_options') }</p>
            </div>
          )}
        </div>
        {img2imgMode !== "0" && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Input_Image') }
            </div>
            <div className="col-md-9">
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={this.selectInputImage} />
              </label>
              {previewInputImage && (
                <div>
                  <img className="preview" src={previewInputImage} alt="" />
                </div>
              )}
            </div>
          </div>
        )}
        {(img2imgMode === "1" || img2imgMode > "2") && inputImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Style_Strength') }
            </div>
            <div className="col-md-9">
              <div className="input-group mb-3">
                <RangeStepInput min={1} max={99} step={1} value={styleStrength} onChange={this.onChangeOfStyleStrength} />
                <div className="input-group-append">
                    <span className="input-group-text ml-2">
                        {styleStrength}%
                    </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-6 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(styleDescription && (img2imgMode === "0" || inputImage) && !progress)}
              onClick={() => this.uploadImages(sid, locale)}
            >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
          <div className="col-6 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!authenticated || (progress > 0 && progress < 100)}
              onClick={() => this.recoverResults()}
            >
              { intl.get('Recover_Results') }
            </button>
          </div>
        </div>

        {authenticated && styleDescription && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan4') }
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
