import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import queryString from "query-string";
import intl from 'react-intl-universal';
import { RangeStepInput } from 'react-range-step-input';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-background-editor.service";
import { resizeImage, getSpecialMessageHTML } from "../utils";
import { Context } from "../Context";
import { APP_NAME, TOTAL_FILE_SIZE, BACKGROUND_EDITOR_ACTIONS, FOREGROUND_LOCATIONS, GIF_MODES } from "../constants";
import { PopupShare } from "./Share/share.component";

export default class UploadImages4BackgroundEditor extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectContentFiles = this.selectContentFiles.bind(this);
    //this.uploadImages = this.uploadImages.bind(this);
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      selectedContentFiles: undefined,
      previewImages: [],
      backgroundImage: undefined,
      previewBackgroundImage: undefined,
      action: '0',
      location: '0',
      foregroundTransparency: 0,
      backgroundTransparency: 0,
      boundaryPixelsToBlend: 5,
      gifMode: '0',
      progress: 0,
      message: "",

      resultSizeType: queryParams.resultSizeType,

      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  selectContentFiles = async event => {
    const acceptedFiles = [];
    const images = [];
    let totalSize = 0;
    for (let i = 0; i < event.target.files.length; i++) {
      let currentFile = event.target.files[i];
      const size = currentFile.size;
      if (size < 100)  //probably an invalid image file, skip it
        continue;
      totalSize += size;
      if (totalSize > TOTAL_FILE_SIZE) 
        break;
      if (size > 2097152) {  //no need to resize when size < 2M
        try {
          currentFile = await resizeImage(currentFile);
        } catch(err) {
          this.setState({
            previewImages: undefined,
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
          });
          return;
        }
      }
      acceptedFiles.push(currentFile);
      images.push(URL.createObjectURL(currentFile));
    }

    if (acceptedFiles.length === 0) {
      this.setState({
        selectedContentFiles: undefined,
        previewImages: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_VALID_FILE_FOUND'),
      });
      return;
    }

    this.setState({
      selectedContentFiles: acceptedFiles,
      previewImages: images,
      progress: 0,
      message: ""
    });
  }
  
  onChangeOfAction = event => {
    if (event.target.value === '0') {
      this.setState({
        action: event.target.value,
        backgroundImage: undefined,
        previewBackgroundImage: undefined,
        progress: 0,
        message: "",
      });
    } else {
      this.setState({
        action: event.target.value,
        progress: 0,
        message: "",
      })
    }
  } 

  selectBackgroundImage = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        this.setState({
          backgroundImage: undefined,
          previewBackgroundImage: undefined,
          progress: 0,
          message: intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
        });
        return;
      }
    }
  
    this.setState({
      backgroundImage: currentFile,
      previewBackgroundImage: URL.createObjectURL(currentFile),
      progress: 0,
      message: ""
    });
  }

  onChangeOfLocation = event => {
    this.setState({
      location: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfForegroundTransparency = event => {
    this.setState({
      foregroundTransparency: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfBackgroundTransparency = event => {
    this.setState({
      backgroundTransparency: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfBoundaryPixelsToBlend = event => {
    this.setState({
      boundaryPixelsToBlend: event.target.value,
      progress: 0,
      message: ""
    });
  }

  onChangeOfGifMode = event => {
    this.setState({
      gifMode: event.target.value,
      progress: 0,
      message: ""
    });
  }

  uploadAll(files, sid, locale) {
    this.timer = null;
    this.duration = 20 * 1000 * files.length;
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.upload(files, this.state.action, this.state.location, this.state.foregroundTransparency, this.state.backgroundTransparency, this.state.boundaryPixelsToBlend, this.state.gifMode, this.state.resultSizeType, sid, locale, (event) => {
      //this.setState({
      //  progress: Math.round(((20 / Math.ceil(files.length / 5)) * event.loaded) / event.total),
      //});
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 200)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4BackgroundEditor,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }),
            resultsFolder: reason.response.data.userFolder4BackgroundEditor,
          });
        } else {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
          });
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
  }

  uploadImages(sid, locale) {
    const selectedContentFiles = this.state.selectedContentFiles;
    if (!selectedContentFiles) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    if (this.state.action !== '0' && this.state.backgroundImage === undefined) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_BACKGROUND_IMAGE_SELECTED'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        const selectedFiles = [];
        selectedFiles.push(this.state.selectedContentFiles[0]);
        if (this.state.action !== '0')
          selectedFiles.push(this.state.backgroundImage);
        this.uploadAll(selectedFiles, sid, locale);
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { selectedContentFiles, previewImages, backgroundImage, previewBackgroundImage, action, location, foregroundTransparency, backgroundTransparency, boundaryPixelsToBlend, gifMode, progress, message, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Background_Editor') }</title>
          <meta name="description" content={ intl.get('BackgroundEditor_info') } />
        </Helmet>
        <div className="title">{ intl.get('Background_Editor') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}
        
        {(!authenticated || user.displayMode === '1') && this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-5 align-self-center introduction">
              { intl.getHTML('BackgroundEditor_description') }
            </div>
            <div className="col-7 text-center">
              <img src="/images/background-editor2.jpg" alt="BackgroundEditor example 2" className="img-fluid" />
            </div>
          </div>
        )}
        {(!authenticated || user.displayMode === '1') && !this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('BackgroundEditor_description') }
              </div>
              <img src="/images/background-editor2.jpg" alt="BackgroundEditor example 2" className="img-fluid" />
            </div>
          </div>
        )}

        {!authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              { intl.getHTML('MESSAGE_LOGIN_BENIFIT') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('BackgroundEditor_instruction', { size_in_mb: TOTAL_FILE_SIZE / 1048576 }) } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="image/*" onChange={this.selectContentFiles} />
            </label>
          </div>
        </div>
        {previewImages && (
          <div className="row m-2">
            <div className="col-12">
              {previewImages.map((img, i) => {
                return <img className="preview" src={img} alt={"image-" + i}  key={i}/>;
              })}
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Action') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfAction}>
              {BACKGROUND_EDITOR_ACTIONS.filter(item => !(['2'].includes(item.value))).map(item => {
                return (<option key={item.value} value={item.value} defaultValue={action}>{ intl.get('BACKGROUND_EDITOR_ACTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>
        {action === '1' && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Background_Image') }
            </div>
            <div className="col-md-9">
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={this.selectBackgroundImage} />
              </label>
              {previewBackgroundImage && (
                <div>
                  <img className="preview" src={previewBackgroundImage} alt="" />
                </div>
              )}
            </div>
          </div>
        )}
        {action === '1' && backgroundImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Foreground_Location') }
            </div>
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" onChange={this.onChangeOfLocation}>
                {FOREGROUND_LOCATIONS.map(item => {
                  return (<option key={item.value} value={item.value} defaultValue={location}>{ intl.get('FOREGROUND_LOCATIONS_' + item.value) }</option>);
                })}
              </select>
            </div>
          </div>
        )}
        {action === '1' && backgroundImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Foreground_Transparency') }
            </div>
            <div className="col-md-9">
              <div className="input-group mb-3">
                <RangeStepInput min={0} max={99} step={1} value={foregroundTransparency} onChange={this.onChangeOfForegroundTransparency} />
                <div className="input-group-append">
                    <span className="input-group-text ml-2">
                        {foregroundTransparency}%
                    </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {action === '1' && backgroundImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Background_Transparency') }
            </div>
            <div className="col-md-9">
              <div className="input-group mb-3">
                <RangeStepInput min={0} max={99} step={1} value={backgroundTransparency} onChange={this.onChangeOfBackgroundTransparency} />
                <div className="input-group-append">
                    <span className="input-group-text ml-2">
                        {backgroundTransparency}%
                    </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {action === '1' && backgroundImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Boundary_Pixels_To_Blend') }
            </div>
            <div className="col-md-9">
              <div className="input-group mb-3">
                <RangeStepInput min={0} max={60} step={1} value={boundaryPixelsToBlend} onChange={this.onChangeOfBoundaryPixelsToBlend} />
                <div className="input-group-append">
                    <span className="input-group-text ml-2">
                        {boundaryPixelsToBlend}
                    </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {action === '1' && backgroundImage && (
          <div className="row m-2">
            <div className="col-md-3">
              { intl.get('Create_Animated_Gif') }
            </div>
            <div className="col-md-9">
              <select className="selectpicker form-control" data-style="btn-success" disabled={!authenticated || !user.subscriptionPlanId} onChange={this.onChangeOfGifMode}>
                {GIF_MODES.map(item => {
                  return (<option key={item.value} value={item.value} defaultValue={gifMode}>{ intl.get('GIF_MODES_' + item.value) }</option>);
                })}
              </select>
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(selectedContentFiles && (action === '0' || backgroundImage) && !progress)}
              onClick={() => this.uploadImages(sid, locale)}
              >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
        </div>

        {selectedContentFiles && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan1') }
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
