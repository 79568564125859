import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { FiAlignRight, FiXCircle, FiChevronDown } from 'react-icons/fi';
import { Preferences } from '@capacitor/preferences';

import './menu.css';
import { SERVER_API_BASE_URL } from "../../constants";
import { Context } from "../../Context";

import intl from 'react-intl-universal';
import enUS from '../../locales/en-US.json';
import frFR from '../../locales/fr-FR.json';
import esES from '../../locales/es-ES.json';

const Navbarmenu = (props) => {
    const [isMenu, setIsMenu] = useState(false);
    const [isResponsiveClose, setResponsiveClose] = useState(false);
    const [isMenuSubMenu1, setMenuSubMenu1] = useState(false);
    const [isMenuSubMenu2, setMenuSubMenu2] = useState(false);
    const [isMenuSubMenu3, setMenuSubMenu3] = useState(false);
    const { authenticated, locale, setLocale } = useContext(Context);

    const LOCALE_LANGUAGE = {'en-US': 'EN', 'fr-FR': 'FR', 'es-ES': 'ES'};
    const LANGUAGE_LOCALE = {'EN': 'en-US', 'FR': 'fr-FR', 'ES': 'es-ES'};
    const LOCALE_DATA = {'en-US': enUS, 'fr-FR': frFR, 'es-ES': esES};
    const setCurrentLocale = (lang) => {
        intl.init({
            currentLocale: LANGUAGE_LOCALE[lang],
            locales: LOCALE_DATA,
        });
        if (locale !== LANGUAGE_LOCALE[lang]) {
            setLocale(LANGUAGE_LOCALE[lang]);
            //setTitle(intl.get('APP_TTITLE'));
            //setDescription(intl.get('APP_DESCRIPTION'));
        }
    };
/*
    const setTitle = title => {
        const el = document.querySelector('title');
        el.innerText = title;
    };
    
    const setDescription = desc => {
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', desc)
    }
*/
    const defaultLanguage = LOCALE_LANGUAGE[locale];    //window.localStorage.getItem('language');
    const [language, setLanguage] = useState(''); 
    useEffect(() => { 
        setLanguage(defaultLanguage);
    }, [defaultLanguage])
    if (language) {
        setCurrentLocale(language);
    }

    const changeLanguage = (lang) => {
        setLanguage(lang);
        Preferences.set({key: 'locale', value: LANGUAGE_LOCALE[lang]});   //window.localStorage.setItem('language', lang);
        setCurrentLocale(lang);
        //window.location.reload();
    };

    const location = useLocation();
    useEffect(() => {
        let previousRoute = location.pathname;
        if (previousRoute.indexOf('/ActivateAccount') !== -1 || previousRoute.indexOf('/ResetPassword') !== -1)
            previousRoute = '/';
        if (previousRoute !== '/Login')
            window.localStorage.setItem('previousRoute', JSON.stringify(previousRoute));
    }, [location.pathname]);
        
    const toggleClass = (e) => {
        setIsMenu(isMenu === false ? true : false);
        setResponsiveClose(isResponsiveClose === false ? true : false);
        setMenuSubMenu1(false);
        setMenuSubMenu2(false);
        setMenuSubMenu3(false);

        // close the submenu box
        let menu = e.target.parentElement.parentElement.parentElement;
        if (menu.classList.contains("sub__menus__arrows")) {
            menu.style.display = "none";
            setTimeout(() => { 
                menu.style.display = "";
                e.target.blur();
            }, 100);
        }
    };

    let boxClass = ["main-menu menu-right menuq1"];
    if (isMenu) {
        boxClass.push('menuq2');
    } else {
        boxClass.push('');
    }

    const toggleSubmenu1 = () => {
        setMenuSubMenu1(isMenuSubMenu1 === false ? true : false);
        setMenuSubMenu2(false);
        setMenuSubMenu3(false);
    };
    let boxClassSubMenu1 = ["sub__menus"];
    if (isMenuSubMenu1) {
        boxClassSubMenu1.push('sub__menus__Active');
    } else {
        boxClassSubMenu1.push('');
    }

    const toggleSubmenu2 = () => {
        setMenuSubMenu2(isMenuSubMenu2 === false ? true : false);
        setMenuSubMenu1(false);
        setMenuSubMenu3(false);
    };
    let boxClassSubMenu2 = ["sub__menus"];
    if (isMenuSubMenu2) {
        boxClassSubMenu2.push('sub__menus__Active');
    } else {
        boxClassSubMenu2.push('');
    }

    const toggleSubmenu3 = () => {
        setMenuSubMenu3(isMenuSubMenu3 === false ? true : false);
        setMenuSubMenu1(false);
        setMenuSubMenu2(false);
    };
    let boxClassSubMenu3 = ["sub__menus"];
    if (isMenuSubMenu3) {
        boxClassSubMenu3.push('sub__menus__Active');
    } else {
        boxClassSubMenu3.push('');
    }

    const handleLogin = () => {
        // Login in a new window as the function doesn't work properly within Android app
        window.open(`${new URL(SERVER_API_BASE_URL).origin}/Login`, "_self");
    };

    const handleLogout = () => {
        // Logout by calling passport api
        window.open(`${SERVER_API_BASE_URL}/auth/logout?from=${window.localStorage.getItem('previousRoute')}`, "_self");
    };

    const buttonClass = props.isLargeScreen ? "btn mb-2 mr-2 btn-info" : "btn mb-2 mr-2 btn-info btn-sm";

    return (
        <header className="header__middle">

        {/* Add Logo  */}
        <div className="header__middle__logo">
            <NavLink exact activeclassname='is-active' to="/">
                <img src="/logo2.png" alt="logo" /> 
            </NavLink>
        </div>
        
        {/* Add login/logout button */}
        <div className="header__middle__button btn-group">
            {/*authenticated ? (
                <NavLink><button className={buttonClass} style={{ whiteSpace: "nowrap" }} onClick={handleLogout}>{intl.get('SIGN_OUT')}</button></NavLink>
            ) : props.isNativePlatform ? (
                <NavLink><button className={buttonClass} style={{ whiteSpace: "nowrap" }} onClick={handleLogin}>{intl.get('SIGN_IN')}</button></NavLink>
            ) : (
                <NavLink to={{ pathname: "/Login", state: { from: location } }}><button className={buttonClass} style={{ whiteSpace: "nowrap" }}>{intl.get('SIGN_IN')}</button></NavLink>
            )*/}

            <div className="dropdown">
                <button className={buttonClass} id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {language}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button className="dropdown-item" onClick={() => changeLanguage("EN")}>English</button>
                    <button className="dropdown-item" onClick={() => changeLanguage("FR")}>Française</button>
                    <button className="dropdown-item" onClick={() => changeLanguage("ES")}>Español</button>
                </div>
            </div>
        </div>

        <div className="header__middle__menus">
            <nav className="main-nav " >

            {/* Responsive Menu Button */}
            {isResponsiveClose === true ? <> 
                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
            </> : <> 
                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
            </>}


            <ul className={boxClass.join(' ')}>
            {/* <li className="menu-item" >
                <NavLink exact activeclassname='is-active' onClick={toggleClass} to={`/`}> Home </NavLink> 
            </li> */}
            <li onClick={toggleSubmenu1} className="menu-item sub__menus__arrows" > <Link to="#"> {intl.get('Picture_Tools')} <FiChevronDown /> </Link>
                <ul className={boxClassSubMenu1.join(' ')} > 
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Cartoonizer`}> {intl.get('Cartoonizer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Enhancer`}> {intl.get('Enhancer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Sketcher`}> {intl.get('Sketcher')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Enlarger`}> {intl.get('Enlarger')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Filter`}> {intl.get('Filter')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Stylizer`}> {intl.get('Stylizer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/MultiStylizer`}> {intl.get('MultiStylizer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/SuperStylizer`}> {intl.get('Super_Stylizer')} </NavLink> </li> 
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/PromptBuilder`}> {intl.get('Prompt_Builder')} </NavLink> </li> 
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/BackgroundEditor`}> {intl.get('Background_Editor')} </NavLink> </li>
                </ul>
            </li>
            <li onClick={toggleSubmenu2} className="menu-item sub__menus__arrows" > <Link to="#"> {intl.get('Video_Tools')} <FiChevronDown /> </Link>
                <ul className={boxClassSubMenu2.join(' ')} > 
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/VideoCartoonizer`}> {intl.get('Video_Cartoonizer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/VideoEnhancer`}> {intl.get('Video_Enhancer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/VideoFilter`}> {intl.get('Video_Filter')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/VideoBackgroundEditor`}> {intl.get('Video_Background_Editor')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/SpeechSynthesizer`}> {intl.get('Speech_Synthesizer')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/StorybookMaker`}> {intl.get('Storybook_Maker')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/VideoPlusStudio`}> {intl.get('Videoplus_Studio')} </NavLink> </li>
                </ul>
            </li>
            <li onClick={toggleSubmenu3} className="menu-item sub__menus__arrows" > <Link to="#"> {intl.get('More')} <FiChevronDown /> </Link>
                <ul className={boxClassSubMenu3.join(' ')} > 
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Gallery`}> {intl.get('Gallery')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Blog`}> {intl.get('Blog')} </NavLink> </li>
                    {/*
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/Pricing`}> {intl.get('Pricing')} </NavLink> </li>
                    <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/MyAccount`}> {intl.get('My_Account')} </NavLink> </li>
                    */}
                    {/* <li className="menu-item"> <NavLink onClick={toggleClass} activeclassname='is-active' to={`/SpecialOffer`}> {intl.get('Special_Offer')} </NavLink> </li> */}
                </ul>
            </li>

            </ul>

            </nav>     
        </div>   

    </header>
    )
}

export default Navbarmenu;
