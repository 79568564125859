import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';
import HashMap from 'hashmap';
import { RangeStepInput } from 'react-range-step-input';

import UploadService from "../services/file-upload-speech-synthesizer.service";
import { Context } from "../Context";
import { resizeImage, getSpecialMessageHTML, getResultsFolder } from "../utils";
import { APP_NAME, MAX_AUDIO_FILE_SIZE, SERVER_API_BASE_URL, GENDER_OPTIONS, PRESENTER_SHAPE_OPTIONS } from "../constants";
import { PopupShare } from "./Share/share.component";

const SpeechSynthesizer = (props) => {
  const customValue = 'custom';
  const { dynamicText, authenticated, sid, locale, user, ttsLanguages, ttsVoices, defaultPresenters, backgroundImages } = useContext(Context);
  const [selectedVoice, setSelectedVoice] = useState(undefined);
  const [voiceSampleUrl, setVoiceSampleUrl] = useState(undefined);
  //const [clonable, setClonable] = useState(false);
  const [inputText, setInputText] = useState(undefined);
  const [inputAudio, setInputAudio] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [resultsFolder, setResultsFolder] = useState(undefined);
  const [fileInfos, setFileInfos] = useState([]);
  const [useCustomVoice, setUseCustomVoice] = useState(false);
  const [customAudio, setCustomAudio] = useState(undefined);
  const [usePresenter, setUsePresenter] = useState(false);
  const [selectedPresenter, setSelectedPresenter] = useState(customValue);
  const [presenterFile, setPresenterFile] = useState(undefined);
  const [previewPresenterImage, setPreviewPresenterImage] = useState(undefined);
  const [useMovingHead, setUseMovingHead] = useState(true);
  const [overrideDrivingAudio, setOverrideDrivingAudio] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [adjustAudioProperties, setAdjustAudioProperties] = useState(false);
  const [audioPitch, setAudioPitch] = useState(1.0);
  const [audioSpeed, setAudioSpeed] = useState(1.0);
  const [audioVolume, setAudioVolume] = useState(0);
  const [useBackgroundImage, setUseBackgroundImage] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(customValue);
  const [backgroundImageFile, setBackgroundImageFile] = useState(undefined);
  const [previewBackgroundImage, setPreviewBackgroundImage] = useState(undefined);
  const [presenterShape, setPresenterShape] = useState('original');
  const [presenterLocation, setPresenterLocation] = useState(4);
  const [presenterHeight, setPresenterHeight] = useState(25);
  const [showLocationLegend, setShowLocationLegend] = useState(false);
  let timer = null;

  const languageMap = useRef();
  const languageOptions = useRef();
  const [voiceOptions, setVoiceOptions] = useState([]);
  const [presenterOptions, setPresenterOptions] = useState([]);
  const [backgroundImageOptions, setBackgroundImageOptions] = useState([]);
  
  // Sort the languages by code
  const compareLanguages = (a, b) => {
    if (a.name > b.name) return 1; 
    if (a.name < b.name) return -1; 
    return 0;
  }
  // Sort the voices by voice_id
  const compareVoices = (a, b) => {
    const key1 = `${9 - a.clonable}${a.voice_id}`
    const key2 = `${9 - b.clonable}${b.voice_id}`
    if (key1 > key2) return 1; 
    if (key1 < key2) return -1; 
    return 0;
  }

  useEffect(() => { 
    if (ttsLanguages.length > 0 && !languageMap.current) {
      languageMap.current = new HashMap();
      languageOptions.current = [];
      languageOptions.current.push({ value: "", text: "GENDER_OPTIONS_" });
      ttsLanguages.sort(compareLanguages).forEach(x => {
        languageMap.current.set(x.code, { "name": x.name, "test_text": x.test_text });
        languageOptions.current.push({ value: x.code, text: x.name });
      })
    }

    if (ttsVoices.length > 0 && languageMap.current && locale) {
      const options = [];
      ttsVoices.sort(compareVoices).forEach(v => {
        const voiceLanguage = v.voice_id.substring(0, 3);
        if ((!selectedGender || selectedGender === v.sex) && (!selectedLanguage || selectedLanguage === voiceLanguage)) {
          let detail = v.voice_id;
          detail += ' (' + intl.get(languageMap.current.get(voiceLanguage)?.name);
          if (v.sex)
            detail +=  ', ' + intl.get(v.sex);
          if (v.clonable) 
            detail +=  ', ' + intl.get(`clonable_${v.clonable}`);
          detail +=  ')';
          options.push({ value: v.voice_id, text: detail });
        }
      });
      setVoiceOptions(options);

      if (options && options.length > 0) {
        if (!selectedVoice) {
          const voice = options[0].value;
          setVoiceSampleUrl(new URL(SERVER_API_BASE_URL).origin + '/assets/voiceSamples/aiTransformer-synthesized_' + voice + '.mp3');
          setInputText(languageMap.current.get(voice.substring(0, 3))?.test_text);
          //if (ttsVoices.find(x => x.voice_id === voice)?.clonable) {
          //  setClonable(true);
          //} else {
          //  setClonable(false);
          //  setUseCustomVoice(false);
          //}
          setSelectedVoice(voice);
        }
      } else {
          setVoiceSampleUrl(undefined);
          setInputText("");
          //setClonable(false);
          setUseCustomVoice(false);
      }
    }

    if (ttsVoices.length > 0 && defaultPresenters.length > 0 && locale) {
      const options = [];
      options.push({ value: customValue, text: intl.get('custom_presenter_option') });
      let myPresenters;
      if (selectedGender) {
        myPresenters = defaultPresenters.filter(a => a.sex === selectedGender);
        if (!myPresenters.find(t => t.name === selectedPresenter.split(' ')[1]))  
          setPreviewPresenterImage(undefined);
      } else {
        myPresenters = defaultPresenters;
      }
      myPresenters.forEach(v => {
        options.push({ value: 'public ' + v.name, text: intl.get('public') + ' - ' + v.name });
      });
      setPresenterOptions(options);
    }

    if (ttsVoices.length > 0 && backgroundImages.length > 0 && locale) {
      const options = [];
      options.push({ value: customValue, text: intl.get('custom_background_image_option') });
      backgroundImages.forEach(v => {
        options.push({ value: 'public ' + v.name, text: intl.get('public') + ' - ' + v.name });
      });
      setBackgroundImageOptions(options);
    }

    if (resultsFolder !== undefined) {
      UploadService.getFiles(resultsFolder).then((response) => {
        setFileInfos(response.data);
      }); 
    }
  }, [ttsLanguages, ttsVoices, defaultPresenters, backgroundImages, selectedGender, selectedLanguage, selectedVoice, selectedPresenter, locale, resultsFolder]);

  const onChangeOfSelectedGender = event => {
    const newValue = event.target.value;
    setSelectedGender(newValue);
    if (newValue) {
      setSelectedVoice(undefined);
    }
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectedLanguage = event => {
    const newValue = event.target.value;
    setSelectedLanguage(newValue);
    if (newValue) {
      setSelectedVoice(undefined);
    }
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectedVoice = event => {
    const newValue = event.target.value;
    setSelectedVoice(newValue);
    setVoiceSampleUrl(new URL(SERVER_API_BASE_URL).origin + '/assets/voiceSamples/aiTransformer-synthesized_' + newValue + '.mp3');
    setInputText(languageMap.current.get(newValue.substring(0, 3))?.test_text);
    //if (ttsVoices.find(x => x.voice_id === newValue)?.clonable) {
    //  setClonable(true);
    //} else {
    //  setClonable(false);
    //  setUseCustomVoice(false);
    //}
    setProgress(0);
    setMessage("");
  } 

  const changeInputText = event => {
    setInputText(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const updatePresenterOptions = () => {
    setSelectedPresenter(customValue);
    setPreviewPresenterImage(undefined);
    if (defaultPresenters.length > 0 && locale) {
      presenterOptions.current = [];
      presenterOptions.current.push({ value: customValue, text: intl.get('custom_presenter_option') });
      let myPresenters;
      if (selectedGender) {
        myPresenters = defaultPresenters.filter(a => a.sex === selectedGender);
      } else {
        myPresenters = defaultPresenters;
      }
      myPresenters.forEach(v => {
        presenterOptions.current.push({ value: 'public ' + v.name, text: intl.get('public') + ' - ' + v.name });
      });
    }
  } 

  const onChangeOfUseCustomVoice = event => {
    setUseCustomVoice(event.target.checked);
    setCustomAudio(undefined);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAdjustAudioProperties = event => {
    setAdjustAudioProperties(event.target.checked);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioPitch = event => {
    setAudioPitch(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioSpeed = event => {
    setAudioSpeed(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfAudioVolume = event => {
    setAudioVolume(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfUsePresenter = event => {
    const isChecked = event.target.checked;
    setUsePresenter(isChecked);
    setProgress(0);
    setMessage("");
    if (!presenterOptions.current)
      updatePresenterOptions();
  } 

  const onChangeOfUseMovingHead = event => {
    const isChecked = event.target.checked;
    setUseMovingHead(isChecked);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfOverrideDrivingAudio = event => {
    setOverrideDrivingAudio(event.target.checked);
    setInputAudio(undefined);
    setProgress(0);
    setMessage("");
  } 

  const selectCustomAudio = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100 || currentFile.size > MAX_AUDIO_FILE_SIZE) {  //a too small file is probably invalid
      setCustomAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
  
    const url = URL.createObjectURL(currentFile);
    const audio = new Audio(url);
    audio.onerror = () => {
      setCustomAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
    audio.onloadedmetadata = () => {
      if (isNaN(audio.duration)) {   //not a valid audio
        setCustomAudio(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
        return;
      }
    }

    setCustomAudio(currentFile);
    setProgress(0);
    setMessage("");
  }

  const onChangeOfSelectPresenter = event => {
    const newPresenter = event.target.value;
    setSelectedPresenter(newPresenter);
    if (newPresenter === "custom") {
      setPresenterFile(undefined);
      setPreviewPresenterImage(undefined);
      setProgress(0);
      setMessage("");
      return;
    }
    const fileName = `${newPresenter}.jpg`;
    const imageUrl = `${SERVER_API_BASE_URL}/presenters/${newPresenter}`;
    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {
        const iamgeFile = new File([imageBlob], fileName);
        setPresenterFile(iamgeFile);
        setPreviewPresenterImage(URL.createObjectURL(iamgeFile));
        setProgress(0);
        setMessage("");
      });
  } 

  const selectPresenterFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        setPresenterFile(undefined);
        setPreviewPresenterImage(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));        
        return;
      }
    }
    setPresenterFile(currentFile);
    setPreviewPresenterImage(URL.createObjectURL(currentFile));
    setProgress(0);
    setMessage("");
  }

  const selectInputAudio = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size < 100 || currentFile.size > MAX_AUDIO_FILE_SIZE) {  //a too small file is probably invalid
      setInputAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
  
    const url = URL.createObjectURL(currentFile);
    const audio = new Audio(url);
    audio.onerror = () => {
      setInputAudio(undefined);
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
      return;
    }
    audio.onloadedmetadata = () => {
      if (isNaN(audio.duration)) {   //not a valid audio
        setInputAudio(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));
        return;
      }
    }

    setInputAudio(currentFile);
    setProgress(0);
    setMessage("");
  }

  const onChangeOfUseBackgroundImage = event => {
    const isChecked = event.target.checked;
    setUseBackgroundImage(isChecked);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPresenterShape = event => {
    setPresenterShape(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPresenterLocation = event => {
    setPresenterLocation(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfPresenterHeight = event => {
    setPresenterHeight(event.target.value);
    setProgress(0);
    setMessage("");
  } 

  const onChangeOfSelectBackgroundImage = event => {
    const newBackgroundImage = event.target.value;
    setSelectedBackgroundImage(newBackgroundImage);
    if (newBackgroundImage === "custom") {
      setBackgroundImageFile(undefined);
      setPreviewBackgroundImage(undefined);
      setProgress(0);
      setMessage("");
      return;
    }
    const fileName = `${newBackgroundImage}.jpg`;
    const imageUrl = `${SERVER_API_BASE_URL}/backgroundImages/${newBackgroundImage}`;
    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {
        const iamgeFile = new File([imageBlob], fileName);
        setBackgroundImageFile(iamgeFile);
        setPreviewBackgroundImage(URL.createObjectURL(iamgeFile));
        setProgress(0);
        setMessage("");
      });
  } 

  const selectBackgroundImageFile = async event => {
    let currentFile = event.target.files[0];
    if (currentFile.size > 2097152 || currentFile.size < 100) {  //no need to resize when size < 2M, and a too small file is probably invalid
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        setBackgroundImageFile(undefined);
        setPreviewBackgroundImage(undefined);
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }));        
        return;
      }
    }
    setBackgroundImageFile(currentFile);
    setPreviewBackgroundImage(URL.createObjectURL(currentFile));
    setProgress(0);
    setMessage("");
  }

  const recoverResults = async () => {
    setProgress(1);
    const resultsDir = await getResultsFolder('userFolder4SpeechSynthesizer');
    if (!resultsDir) {
      setMessage(intl.get("No_results"));
    } else {
      setResultsFolder(resultsDir);
    }
    setProgress(100);
  }

  const uploadAll = (files, voice, pitch, speed, volume, shape, location, height, sid, locale) => {
    let duration = 6 * 60 * 1000;
    //if (usePresenter && selectedPresenter !== customPresenterValue)   //it runs longer using a default driving video
    //  duration *= 3;
    const startTime = Date.now();
    timer = null;
    setProgress(0);
    setMessage("");

    UploadService.getServerStatus().then((serverStatus) => {
      if (serverStatus < 0) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_UNAVAILABLE'));
      }
      else if (serverStatus < 1) {
        setProgress(0);
        setMessage(intl.getHTML('ERROR_MESSAGE_SERVER_BUSY'));
      }
      else UploadService.upload(files, voice, inputText, useCustomVoice, pitch, speed, volume, usePresenter, useMovingHead, overrideDrivingAudio, useBackgroundImage, shape, location, height, sid, locale, (event) => {
        if (timer === null)
          timer = setInterval(() => {
            const percentage = Math.round(((Date.now() - startTime) / duration) * 100);
            if (percentage > 96) 
              clearInterval(timer);
            else 
              setProgress(percentage);
          }, 500)
        }).then((response) => {
          clearInterval(timer);
          setMessage(response.data.message);
          setProgress(100);
          setResultsFolder(response.data.userFolder4SpeechSynthesizer);
        })
        .catch((reason) => {
          clearInterval(timer);
          if (reason.code === 'ECONNABORTED') {
            // handle timeout error
            if (resultsFolder) {
              setProgress(100);
            } else {
              //setProgress(0);
              //setMessage(intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }));
              recoverResults();
              setProgress(100);
            }
          } else {
            // handle other errors
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
              setProgress(0);
              setMessage(intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message }));
              setResultsFolder(reason.response.data.userFolder4SpeechSynthesizer);
            } else {
              setProgress(0);
              setMessage(intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }));
            }
          }
        })
        .finally(() => {
          if (typeof resultsFolder !== "undefined")
            UploadService.getFiles(resultsFolder)
            .then((processedFiles) => {
              setFileInfos(processedFiles.data);
            })
        })
    });
  }

  const uploadToProcess = (sid, locale) => {
    let voice = selectedVoice;
    if (!voice && voiceOptions && voiceOptions.length > 0) {
      voice = voiceOptions[0].value;
    }
    if (!voice || !inputText) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_VOICE_OR_TEXT'));
      return;
    }
    if (useCustomVoice && !customAudio) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_VOICE'));
      return;
    }
    if (usePresenter && (selectedPresenter === customValue) && !presenterFile) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_PRESENTER'));
      return;
    }
    if (overrideDrivingAudio && !inputAudio) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_DRIVING_AUDIO'));
      return;
    }
    if (useBackgroundImage && (selectedBackgroundImage === customValue) && !backgroundImageFile) {
      setProgress(0);
      setMessage(intl.getHTML('ERROR_MESSAGE_MISSING_CUSTOM_BACKGROUND_IMAGE'));
      return;
    }
    const selectedFiles = [];
    if (useCustomVoice && customAudio) {
      selectedFiles.push(customAudio);
    }
    if (usePresenter && presenterFile) {
      selectedFiles.push(presenterFile);
    }
    if (usePresenter && overrideDrivingAudio && inputAudio) {
      selectedFiles.push(inputAudio);
    }
    if (useBackgroundImage && backgroundImageFile) {
      selectedFiles.push(backgroundImageFile);
    }
    let pitch = 1;
    let speed = 1;
    let volume = 0;
    if (adjustAudioProperties) {
      pitch = audioPitch;
      speed = audioSpeed;
      volume = audioVolume;
    }
    let shape = 'original';
    let location = 4;
    let height = 25;
    if (usePresenter && useBackgroundImage) {
      shape = presenterShape;
      location = presenterLocation;
      height = presenterHeight;
    }

    uploadAll(selectedFiles, voice, pitch, speed, volume, shape, location, height, sid, locale);
  }

  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);
  const ulClassName = props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
  //const demoFileName = 'SpeechSynthesizer2_' + locale.substring(0, 2) + '.mp4';

  return (
    <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{APP_NAME} { intl.get('Speech_Synthesizer') }</title>
        <meta name="description" content={ intl.get('SpeechSynthesizer_info') } />
      </Helmet>
      <div className="title">{ intl.get('Speech_Synthesizer') }</div>
      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}

      {(!authenticated || user.displayMode === '1') && props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-6 align-self-center introduction">
            { intl.getHTML('SpeechSynthesizer_description') } <br/>{ intl.getHTML('reserved_tool_note') }
          </div>
          <div className="col-6 text-center embed-responsive embed-responsive-1by1">
            <video className="embed-responsive-item" title={ intl.get('SpeechSynthesizer_demo_video') + '_2'} src={new URL(SERVER_API_BASE_URL).origin + '/assets/SpeechSynthesizer2_' + locale.substring(0, 2) + '.mp4'} type="video/mp4" controls autoPlay></video> 
          </div>
        </div>
      )}
      {(!authenticated || user.displayMode === '1') && !props.isLargeScreen && (
        <div className="row m-1">
          <div className="col-12 text-center">
            <div className="introduction">
              { intl.getHTML('SpeechSynthesizer_description') } <br/>{ intl.getHTML('reserved_tool_note') }
            </div>
            <div className="embed-responsive embed-responsive-1by1"> 
              <video className="embed-responsive-item" title={ intl.get('SpeechSynthesizer_demo_video') + '_2' } src={new URL(SERVER_API_BASE_URL).origin + '/assets/SpeechSynthesizer2_' + locale.substring(0, 2) + '.mp4'} type="video/mp4" controls autoPlay></video> 
            </div>
          </div>
        </div>
      )}
        
      {!authenticated && (
        <div className="row highlight m-2">
          <div className="col-12">
            { intl.getHTML('Super_Stylizer_usage_quota_message') }
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-12">
          { intl.getHTML('SpeechSynthesizer_instruction', { size_in_mb: MAX_AUDIO_FILE_SIZE / 1048576 }) } { intl.get('download_results_message') }
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Filters') }
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Gender') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedGender}>
            {GENDER_OPTIONS.map(item => {
              return item.value === selectedGender ? 
              (<option key={item.value} value={item.value} selected>{ intl.get('GENDER_OPTIONS_' + item.value) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get('GENDER_OPTIONS_' + item.value) }</option>);
            })}
          </select>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Language') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedLanguage}>
            {languageOptions.current?.map(item => {
              return item.value === selectedLanguage ? 
              (<option key={item.value} value={item.value} selected>{ intl.get(item.text) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get(item.text) }</option>);
            })}
          </select>
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Select_Voice') }
        </div>
        <div className="col-md-9">
          <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectedVoice}>
            {voiceOptions?.map(item => {
              return item.value === selectedVoice ? 
              (<option key={item.value} value={item.value} selected>{ item.text }</option>)
              : (<option key={item.value} value={item.value}>{ item.text }</option>);
            })}
          </select>
        </div>
      </div>
      
      {voiceSampleUrl && (
        <div className="row m-2">
          <div className="col-md-3">
            { intl.get('Voice_Sample') }
          </div>
          <div className="col-md-9">
            <audio controls src={voiceSampleUrl} />
          </div>
        </div>
      )}
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Text_To_Synthesize') }
        </div>
        <div className="col-md-9">
          <textarea className="form-control" value={inputText} onChange={changeInputText} />
        </div>
      </div>
      
      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Use_Custom_Voice') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={useCustomVoice} onChange={onChangeOfUseCustomVoice} />
        </div>
      </div>
      {useCustomVoice && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.getHTML('Voice_To_Clone', { size_in_mb: MAX_AUDIO_FILE_SIZE / 1048576 }) }
          </div>
          <div className="col-md-9">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="audio/*" onChange={selectCustomAudio} />
            </label>
            {customAudio && (
              <div>
                <audio controls src={URL.createObjectURL(customAudio)} />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Adjust_Audio_Properties') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={adjustAudioProperties} onChange={onChangeOfAdjustAudioProperties} />
        </div>
      </div>
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Pitch') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={0.5} max={2.0} step={0.1} value={audioPitch} onChange={onChangeOfAudioPitch} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioPitch}x
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Speed') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={0.5} max={2.0} step={0.1} value={audioSpeed} onChange={onChangeOfAudioSpeed} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioSpeed}x
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {adjustAudioProperties && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Volume') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={-20} max={20} step={1} value={audioVolume} onChange={onChangeOfAudioVolume} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                      {audioVolume}db
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Use_Presenter') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={usePresenter} onChange={onChangeOfUsePresenter} />
        </div>
      </div>
      {usePresenter && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Select_Presenter') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectPresenter}>
              {presenterOptions?.map(item => {
                return item.value === selectedPresenter ? 
                (<option key={item.value} value={item.value} selected>{ item.text }</option>)
                : (<option key={item.value} value={item.value}>{ item.text }</option>);
              })}
            </select>
            {(!selectedPresenter || selectedPresenter === customValue) && (
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={selectPresenterFile} />
              </label>
            )}
            {previewPresenterImage && (
              <div>
                <img className="preview" src={previewPresenterImage} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
      {usePresenter &&  (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Use_Moving_Head') }
          </div>
          <div className="col-md-9">
            <input type="checkbox" checked={useMovingHead} onChange={onChangeOfUseMovingHead} />
          </div>
        </div>
      )}
      {usePresenter &&  (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Override_Driving_Audio') }
          </div>
          <div className="col-md-9">
            <input type="checkbox" checked={overrideDrivingAudio} onChange={onChangeOfOverrideDrivingAudio} />
          </div>
        </div>
      )}
      {overrideDrivingAudio && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Driving_Audio') }
          </div>
          <div className="col-md-9">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" accept="audio/*" onChange={selectInputAudio} />
            </label>
            {inputAudio && (
              <div>
                <audio controls src={URL.createObjectURL(inputAudio)} />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-md-3">
          { intl.get('Use_Background_Image') }
        </div>
        <div className="col-md-9">
          <input type="checkbox" checked={useBackgroundImage} onChange={onChangeOfUseBackgroundImage} />
        </div>
      </div>
      {useBackgroundImage && (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Select_Background_Image') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker form-control" data-style="btn-success" onChange={onChangeOfSelectBackgroundImage}>
              {backgroundImageOptions?.map(item => {
                return item.value === selectedBackgroundImage ? 
                (<option key={item.value} value={item.value} selected>{ item.text }</option>)
                : (<option key={item.value} value={item.value}>{ item.text }</option>);
              })}
            </select>
            {(!selectedBackgroundImage || selectedBackgroundImage === customValue) && (
              <label className="btn mb-3 mr-3 btn-light">
                <input type="file" accept="image/*" onChange={selectBackgroundImageFile} />
              </label>
            )}
            {previewBackgroundImage && (
              <div>
                <img className="preview" src={previewBackgroundImage} alt="" />
              </div>
            )}
          </div>
        </div>
      )}

      {usePresenter && useBackgroundImage &&  (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Shape') }
          </div>
          <div className="col-md-9">
            <select className="selectpicker" data-style="btn-success" onChange={onChangeOfPresenterShape}>
              {PRESENTER_SHAPE_OPTIONS.map(item => {
              return item.value === presenterShape ? 
              (<option key={item.value} value={item.value} selected>{ intl.get('PRESENTER_SHAPE_OPTIONS_' + item.value) }</option>)
              : (<option key={item.value} value={item.value}>{ intl.get('PRESENTER_SHAPE_OPTIONS_' + item.value) }</option>);
              })}
            </select>
          </div>
        </div>
      )}
      {usePresenter && useBackgroundImage &&  (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Location') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={1} max={16} step={1} value={presenterLocation} onChange={onChangeOfPresenterLocation} />
              <div className="input-group-append">
                <span className="input-group-text ml-2">
                  {presenterLocation}
                </span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="btn mb-3 mr-3 btn-outline-success"
                onClick={() => setShowLocationLegend(!showLocationLegend)}
              >
                { intl.get('Toggle_Show_Legend') }
              </button>
            </div>
            {showLocationLegend && (
              <div>
                <img src="/images/legendOfPresenterLocation.jpg" alt={intl.get("legendOfPresenterLocation")} className="img-fluid preview" />
              </div>
            )}
          </div>
        </div>
      )}
      {usePresenter && useBackgroundImage &&  (
        <div className="row m-2">
          <div className="col-md-3">
            &nbsp;&nbsp;&nbsp;&nbsp;{ intl.get('Presenter_Height') }&nbsp;{ intl.get('Presenter_Height_extra') }
          </div>
          <div className="col-md-9">
            <div className="input-group mb-3">
              <RangeStepInput min={10} max={90} step={1} value={presenterHeight} onChange={onChangeOfPresenterHeight} />
              <div className="input-group-append">
                  <span className="input-group-text ml-2">
                    {presenterHeight}%
                  </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row m-2">
        <div className="col-6 text-center">
          <button
            className="btn mb-3 mr-3 btn-info btn-lg"
            disabled={!(authenticated && inputText && !progress)}
            onClick={() => uploadToProcess(sid, locale)}
          >
            { intl.get('UPLOAD_TO_PROCESS') }
          </button>
        </div>
        <div className="col-6 text-center">
          <button
            className="btn mb-3 mr-3 btn-info btn-lg"
            disabled={!authenticated || (progress > 0 && progress < 100)}
            onClick={() => recoverResults()}
          >
            { intl.get('Recover_Results') }
          </button>
        </div>
      </div>

      {authenticated && inputText && (
        <div className="progress m-2">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      {message && (
        <div className="alert alert-warning my-1 text-wrap" role="alert">
          {message}
        </div> 
      )}

      {fileInfos.length > 0 && (
        <div className="card">
          <div className="card-header alert alert-success text-center">
            <h4>
              { intl.get('Results') }&nbsp;&nbsp;&nbsp;&nbsp;
              <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
            </h4> 
            { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan4') }
          </div>
          <ul className={ulClassName}>
            {fileInfos.map((media, index) => {
              return media.url.slice(-3) === 'mp3' ?
                (<li className="list-group-item" key={index}>
                  <audio controls className="card-img-top" src={media.url} />
                  <h6 className="card-title">{media.name}&nbsp;&nbsp;&nbsp;&nbsp;<a href={media.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>)
              : (<li className="list-group-item" key={index}>
                <h6 className="card-title">{media.name}&nbsp;&nbsp;&nbsp;&nbsp;<a href={media.url}><b>{ intl.get('Download') }</b></a></h6>
                <div class="embed-responsive embed-responsive-4by3">
                  <video src={media.url} width="320" height="240" controls /> 
                </div>
              </li>)
              })}
          </ul>
        </div>
      )}
    </motion.div>
  );
}

export default SpeechSynthesizer;